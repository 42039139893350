////
/// @group global
/// General Base
////

/// Default eyebrow color
/// @type Color
$eyebrow-color: $secondary-color !default;

/// Default component padding
/// @type Map
$component-padding: (
        none: 0,
        small: 1rem,
        medium: 3rem,
        large: 5rem
) !default;
/*! -------------------------------- -------------------------------- */
/*! ---------------------------- Headings --------------------------- */
/*! -------------------------------- -------------------------------- */


h1, .h1, h2, .h2, h3, .h3, h4, .h4 {

  }


h5, .h5, {
  font-weight: 400;
  font-style: italic;
  font-family: $serif-font-family;
  }

h2 a {
  color: inherit;
  }

.hollow-text {
  -webkit-text-stroke: 1px currentColor;
  -webkit-text-fill-color: transparent;
  }

.button {
  //text-transform: uppercase;
  }

.editorContent a:not([class]){
   font-weight: bold;
}
.logo.demo {
  display: inline-block;
}
/*! -------------------------------- -------------------------------- */
/*! ---------------------------- Sections --------------------------- */
/*! -------------------------------- -------------------------------- */

#contentControl h1.pageTitle {
  display: none;
  }

.content {
  background-color: $white;
  }

section {
  display: block;
  position: relative;
  padding-bottom: 1rem;
  box-sizing: border-box;
  }

.grid-container.wide {
  max-width: 85em;
  }

.grid-container.extra-wide {
  max-width: 120em;
  }

.grid-x.reversed {
  flex-direction: row-reverse;
  }

.forceWhite {
  color: white;
  }

.themeDark {
  background: $theme-dark;
  color: $white;

  & address, p, a:not(.button), h1, h2, h3, h4 {
    color: $white;
    }

  }
.themePrimary {
  background: $primary-color;
  color: color-pick-contrast($primary-color);

  & address, p, a:not(.button), h1, h2, h3, h4 {
    color: color-pick-contrast($primary-color);
    }
  }

//does not change child notes to white
.darkBackground {
  background: $theme-dark;
  }

.themeMedium {
  background: $theme-medium;
  }

.themeLight {
  background-color: $white;
  }

@each $name, $size in $component-padding {
  @each $type in (y, top, bottom) {
    .pad-y-#{$name} {
      padding-top: $size;
      padding-bottom: $size;
    }
    .pad-top-#{$name} {
      padding-top: $size;
    }
    .pad-bottom-#{$name} {
      padding-bottom: $size;
    }
  }

}
.push-to-bottom {
  margin-top: auto;
  justify-self: flex-end;
}
//todo revisit
.moduleHeader {
  margin-bottom: 1.5rem;

  }

/*! -------------------------------- -------------------------------- */
/*! ------------------- Misc. General Site Styling ------------------ */
/*! -------------------------------- -------------------------------- */

.imageLeft {
  float: left;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.imageRight {
  float: right;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

@include breakpoint(medium up){
  .imageHalf {
    width:50%;
  }
}
.eyebrow {
  font-size: 1.125rem;
  font-style: italic;
  color: $eyebrow-color;
  font-family: $serif-font-family;

  }

// stylized link that should be an editor style
// as well as used in some listers / components

.arrowLink, .moreLink {
  color: inherit;
  font-weight: bold;
  }

.arrowLink, .moreLink, .button.arrow,.button.arrow-hover {
  &::after {
    right: 5px;
    left: auto;
    margin-left: 5px;
    transition: transform 0.2s ease;
    display: inline-block;
    border: none;
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
    color: inherit;
    content: '';
    height: 10px;
    width: 10px;
    transform: translateX(0rem) rotate(45deg);
    }

  &:hover, .a:hover & {
    &::after {
      transform: translateX(.5rem) rotate(45deg);
      }
    }
  }
.arrow-hover{
  padding-left: 1.5rem;
}
.arrow-hover:after{
  opacity: 0;
  transform: translateX(-.5rem) rotate(45deg);
}
.arrow-hover:hover:after{
  opacity: 1;
}


/*! -------------------------------------------------------------------- */
/*! Foundation Overrides                                                 */
//*! -------------------------------------------------------------------- */
//.submenu-toggle::after {
//  border: none;
//  border-bottom: 2px solid currentColor;
//  border-left: 2px solid currentColor;
//  color: $dropdownmenu-arrow-color;
//  content: '';
//  height: 10px;
//  width: 10px;
//  transform: translateY(-25%) rotate(-45deg);
//  transition: transform 0.2s ease;
//  }
//
//.submenu-toggle[aria-expanded='true']::after {
//  transform: translateY(25%) rotate(-225deg);
//  }

//flourish - fancy things - svg borders - blockquotes etc
.svgDivider {
  height: 5vw;
  width: 100%;
  position: relative;

  }

.hasSvg--top {
  .grid-container {
    padding-top: calc(2rem + 5vw);
    }
  .svgDivider {
    margin-bottom: -5vw;
    z-index: 1;
    //top: 1px;
    }
  }
.hasSvg--bottom {
  padding-bottom: 0;

  .grid-container {
    padding-bottom: calc(2rem + 5vw);
    }

  .svgDivider {
    margin-top: -5vw;
    z-index: 1;
    }

  }
