////
/// @group Footer
////

/// Default footer background
/// @type Color
$footer-main-background: $theme-dark !default;

/// Default footer link color
/// @type Color
$footer-main-link-color: $primary-color !default;

/// Default footer link weight
/// @type Color
$footer-main-link-weight: 700 !default;

.footer {
  display: block;
  position: relative;
  padding: 0;
  @include colorThemeSection($footer-main-background);

  .footer-brand {
    font-size: 1.2em;
  }
}

.footer-main {
  padding-top: 4rem;
  padding-bottom: 2rem;

  a:not(.button, .socialMenu a) {
    color: $footer-main-link-color;
    font-weight: $footer-main-link-weight;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-logo {
  display: block;
  width: 330px;
  padding: 0;
  margin-bottom: 2rem;
}

.footer-copyright {
  padding: 1rem 0;
  font-size: 0.875rem;

  a {
    color: $footer-main-link-color;

    &:hover {
      text-decoration: underline;
    }
  }

  .accrisoft {
    color: $footer-main-link-color;
  }
}
