
////
/// @group Header
////

/// Default style one menu background
/// @type Color
$header-menu-background: $theme-dark !default;

/// Default  main section background
/// @type Color
$header-background: white !default;

/// Default header icon color
/// @type Color
$header-icon-color: $primary-color !default;


/// All anchors in main navigation
/// @type Color
$header-main-navigation-color: #454545 !default;

/// First level anchors in main navigation
/// @type Color
$header-first-level-color: color-pick-contrast($header-menu-background) !default;



.header {
  display: block;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  z-index: 5;
  background:$header-background;
  border-bottom: 1px solid transparentize($header-menu-background, .2);
  &.is-stuck {
    box-shadow: 0 3px 10px rgba(0,0,0,.3);
    //padding-top: 0.5rem;
  }

  // for when the home header overlays the slideshow
  body.header-is-overlay & {
      background: transparent;
      position: absolute;
      width: 100%;
      border-bottom: 0;
  }

}

a.logo.demo {
  background: gray;
  height: 75px;
  width: 100%;
  }

.header-top {
  padding: 5px 0;
  max-height: 4rem;
  .is-stuck & {
    display: none;
  }
}

@include breakpoint(small only){

  .header-social{
    display: none;
  }
}
.header-buttons {
  margin-left: 1rem;
  flex: 0 0 auto;
  a {
    white-space: nowrap;
    }
    @media screen and (max-width: 640px) {
      a{
        font-size: 0.7rem;
      }
    }
}
.header-util {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 67px;
  justify-self: flex-end;
  margin-left: auto;
  .button, .input-group {
    margin-bottom: 0;
  }
}

.header-search{
  //width:175px;
  flex: 0 0 auto;
  margin-left: 1rem;
  .button {
    margin-bottom: 0;
  }
  .input-group {
    width:175px;
    .input-group-field {
      border: 0;
      box-shadow: none;
      //color:white;
      //&::placeholder {
      //  color: white;
      //}
      &:focus{
        //background: $theme-dark;//todo
      }
    }
  }

}
.header-search-popup {
  position: absolute;
  /* top: 0; */
  width: calc(100% - 50px);
  right: 0;
  display: flex;
  align-items: center;
  background: $secondary-color;
  margin-top: auto;
  max-width: 400px;
  padding: 2rem 2rem 1rem;
  box-shadow: 0 3px 3px rgba(0, 0,0, .3);
  border:0;
}

.header-search[aria-expanded="true"],
.header-search.is-active {
  background: $theme-medium;
}

.header-links {

  &.header-right {display: flex;
  justify-content: end;
    align-items: center;
  }

  a {
    color: inherit;
    display: flex;
    font-size: 14px;
    align-items: center;
  }
}

.header-links a .icon {
  color: $header-icon-color;
  font-size: 2rem;
  margin-right: 8px;
}

.header-main-container{
  background:$header-menu-background;
  }
.header-main {
  align-items: flex-end;
  display: flex;
  //height: 90px;
  justify-content: space-between;
  max-height: 70px;
  transition: height 0.4s ease;
  width: 100%;

  }
.header-logo {
  //color: #FFF;
  flex-shrink: 0;
  margin-right: 40px;
  transition: color 0.4s ease, width 0.4s ease;
  width: 300px;
  .is-stuck & {
    width: 250px;
  }
  @media screen and (max-width: 640px) {
    width: 190px;
    margin-right: 5px;
    padding-bottom: 10px;
  }
}
.header-logo svg {
  width: 100%; }



//.header-nav is the .menu ul
.header-nav  {
  white-space: nowrap;
  align-items:flex-end;
  justify-content: space-between;
  height: 67px;
  max-height: 105px;
  max-width: 940px;
  position: relative;
  width: 100%;
  a {
    color: $header-main-navigation-color;
    font-size: 1rem;
    font-weight: 600;

  }
  li.is-overflown {
    visibility: hidden;
    pointer-events: none;
  }
  //first level anchors
   & > li > a{
    border-bottom: 3px solid transparent;
     //text-transform: uppercase;
     color:$header-main-navigation-color;
   }
  //second level and beyond
  & ul li:hover{
    & > a{ background: $light-gray;}
    //&.is-active > a {
    //  background: $main-navigation-color ;
    //}
  }

  & > li.menuItemHighlight > a, & > li:hover > a {
    border-bottom: 3px solid $dropdown-menu-item-color-active;
  }
}

@include breakpoint(medium down){
  .header-nav {
    display: none;
  }
}
.header .header-mobile-only-trigger {
  display: none;
  background-color: $header-menu-background;
}
/*! Incrementally hide the top bar items*/
@media screen and (max-width: 640px) {
  .header .header-links {
    display: none;
  }
  .header .header-main-container {
    display: none;
  }
  .header .header-mobile-only-trigger {
    display: block;
  }
}

@media screen and (max-width: 925px) {
  .header .header-social {
    display: none;
  }
}

@media screen and (max-width: 880px) {
  .header .header-search {
    display: none;
  }
}

