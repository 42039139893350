/*! ModuleBegin::photo */

/*! Photo Lister */
.photoModule.moduleLister {
}

.albumBox {
  border: 1px solid #ddd;
  border-radius: $global-radius;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

.albumBox .albumImage {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 86%;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.albumBox .albumDesc {
  margin-bottom: 8px;
}

/*! Thumbnails */

.thumbnailItem {
  display: block;
  height: 0;
  padding-bottom: 86%;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  border: 4px solid #fff;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.54);
}

.thumbnailItem:hover {
  border-color: $primary-color;
  transition: border-color 0.1s ease;
}

/*! Slideshow */
.photoModuleSlideshow {
}

.photoModuleSlideshow img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

/*! ModuleEnd::photo */
