@import 'xy-grid/cell';

@include breakpoint(medium up ) {
  .col1-39, .col2-93 {
    @include xy-cell-size(3, $gutter-type: none);
  }
  .col2-39, .col1-93 {
    @include xy-cell-size(9, $gutter-type: none);
  }

  .col1-57, .col2-75 {
    @include xy-cell-size(5, $gutter-type: none);
  }
  .col2-57, .col1-75 {
    @include xy-cell-size(7, $gutter-type: none);
  }

  .col1-48, .col2-84 {
    @include xy-cell-size(4, $gutter-type: none);
  }
  .col2-48, .col1-84 {
    @include xy-cell-size(8, $gutter-type: none);
  }
}
