$login-box-top: -180px !default;
/*! ModuleBegin::membership */

.loginBox{
  margin: 0 auto;
  max-width:450px;
  min-height:522px;
  width: 100%;
  border-radius: $global-radius;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  z-index: 1;
  background: $light-gray;
  position: relative;
  transition: top 450ms;
  top: 0;
}
.primaryLogin.loginBox {
  max-width:932px;
  top: $login-box-top;
}
.ajaxLogin.loginBox {

}
.embeddedLogin.loginBox{

}

.loginForm,.loginCta {
  padding: calc(1rem + 2vw);
  display: flex;
  flex-flow: column;
}
.loginForm {
  background: $white;
}
.loginCta {
  justify-content: center;
  min-height: 250px;
}
.membershipModule .search { }
.membershipModule .companyInfo { margin:1em 0; }
.membershipModule .companyInfo .name { }
.membershipModule .companyInfo .jobTitle { }
.membershipModule .address { margin:1em 0; }
.membershipModule .contactInfo { margin-bottom:1em }
.membershipModule .contactInfo .phone { }
.membershipModule .contactInfo .email { }
.membershipModule .contactInfo .website { }
.membershipModule .thumbnail { float:left; margin-right:20px; }
.membershipModule .detail-image { margin-right: 3rem;}


/*! ----- Membership Lister ----- */
.membershipModule.moduleLister { }
.membershipModule .lister-image{ width: 190px}
.moduleSearchBox {
  background: $light-gray; //todo better variables
  border-top: 2px solid $medium-gray; //todo better variables
  padding:    2rem;
  margin-bottom: 2rem;
}
.moduleSearchBox .formContent, .moduleSearchBox .formGroup   {
  margin-bottom: 0;
}

/*! ---- Membership Detail ----- */
.membershipModule.moduleDetail { }

/*! ----- My Account ---- */
.membershipModule.myAccount { }
.membershipModule.myAccount::after { content: ''; display:block; height:0; clear:both; visibility:hidden; }
.membershipModule.myAccount { }
.myAccountLinks { margin-top:35px; align-items:stretch; }
.myAccountLinks .myAccountBox {border:0; border-bottom: 6px solid $secondary-color; padding: 12px 16px; height:100%; }
.myAccountLinks h3 { padding: 0 0 12px; margin: 0 0 12px; border-bottom: 1px solid #eee;  font-size: 20px}
.myAccountLinks .linkSection { }
.myAccountLinks .linkSection a { display:block; padding:5px 0; color:inherit }
.myAccountLinks .button { text-align:center; padding-top:25px; }
.myAccountLinks .button a { margin:0 0 10px; }

/*! ----- My Account - Dispatch ( System Forms ) ---- */
.freedomAdminTableHeader, .freedomAdminTableHeader table { margin: 0;}
.freedomAdminTableHeader tbody,.freedomAdminTableHeader tr { border:0;}
#freedomAdminTableHeaderWrapper table,#freedomAdminTableHeaderWrapper tbody,#freedomAdminTableHeaderWrapper tr{margin:0;padding:0;border:0}
.myaccountForm, .freedomAdminForm {
  tbody, tr {border: 0}
  input[name$=city], input[name$=state], input[name$=zip],
  #bank_acct_name, #bank_aba_code, select[name$=month], select[name$=year],
  input[name$=time], select[name$=time_ampm]
  {
    width: auto;
    display: inline-block;
  }select[name$=month],select[name$=year]{ width: 40%;}
  input[name$=city] { width:98%;}
  @media screen and (min-width:40em){
    input[name$=city] {width:50%;  }
  }

}


#myaccount_form>div { text-align:left; width:100%; }
.myaccountForm>table, .freedomAdminForm>table { width:100%; }
.myaccountForm>table td, .freedomAdminForm>table td { vertical-align:top; }
.myaccountForm>table td:first-child, .freedomAdminForm>table td:first-child { padding-top:14px; padding-right:12px; }
.myaccountForm>table table, table.freedomTable { width:100%; border: 1px solid #ccc; background:#f8f8f8; margin:0; border-spacing:0; border-collapse:collapse; }
.myaccountForm>table table td, table.freedomTable td, table.freedomTable th { vertical-align:middle; padding:8px 12px; }
.myaccountForm>table table thead td, table.freedomTable thead th { background:#ccc; color:#000; }
.myaccountForm>table table thead td *, table.freedomTable thead th * { color:#000; }
.myaccountForm>table table thead td:first-child, table.freedomTable thead th:first-child { text-align:left; }

.editorLabel { display:inline-block; padding-bottom:15px; }
.myaccountDetail table { color:#444; }
.myaccountDetail .formtextRequired, .myaccountDetail .formtext { padding:5px 0; }
.myaccountDetail .formtextRequired label { font-weight:bold; }
.formButtons { padding-top:15px; }
select.filterCombo { width:200px; }
.imageBoxContainer { width:100%; background:#eee; padding:3px; line-height:38px; }
.imageBoxContainer * { vertical-align:middle !important; text-align:center; }
.imageBoxContainer .imageBox { border: 4px solid #fff; background:#f8f8f8; display:inline-block; width:auto; margin:8px; padding:12px; }
.myaccountMessage { display:block; padding:18px; border: 2px dashed #777; background:#ddd; color:#000; font-size:1.6rem; line-height:22px; text-align:center; margin-bottom:18px; }
.myaccountMessage.defaultSuccess { background:lighten(get-color(success), 50%); border-color:get-color(success); }
.myaccountLister { }
.myaccountListerBack { }

.myaccountCta { padding: 2rem}
.myaccount-infoContainer{
  background-color: $theme-dark;
  padding: 16px 0 1px 0;
  color: #fff;
  margin-bottom: 1rem;}
.myaccount-infoBar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;}
/*! ModuleEnd::membership */