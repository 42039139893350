//colors done by generic theme classes only
.spanningCta {
  &-component{

  }

  &-title , &-action{
    text-align: center;
  }

  @include breakpoint(medium up){
    &-title {
      text-align: left;
    }
    &-action {
      text-align: right;
    }
  }
}