////
/// @group modules - slideshow
////

/// Slideshow height map based on breakpoints
/// @type Map
$slideshow-height-map: (
        small: 500px,
        large: 575px,
        xxlarge: 700px
) !default;

/// Slideshow background
/// @type Color
$slideshow-background: $theme-dark !default;

/// Slideshow Overlay
/// @type Gradient
$slideshow-slide-overlay-bg: linear-gradient(to bottom, $slideshow-background 0%, transparent 100%) !default;

/// Slideshow Overlay Height
/// @type Number
$slideshow-slide-overlay-height: 530px !default;

/// Slideshow content color
/// @type Color
$slide-content-color: #FFF !default;

/// Slideshow color dark theme
/// @type Color
$slide-content-color-dark: $black !default;

/// Slideshow title font family.
/// Ignored if null (default)
/// @type Color
$slideshow-slide-title-family: null !default;


/// Slideshow alert background
/// @type Color
$slideshow-alert-background: $secondary-color !default;

/// Slideshow alert color
/// @type Color
$slideshow-alert-color: $light-gray !default;

/// Slideshow alert icon background. used only in the popup style
/// @type Color
$slideshow-alert-icon-background: $primary-color !default;

/// Slideshow alert icon color - used only in the popup style
/// @type Color
$slideshow-alert-icon-color: color-pick-contrast($primary-color) !default;


.slideshow {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;

  .slides {
    display: block;
    position: relative;
    z-index: 1;
    @each $breakpoint, $size in $slideshow-height-map {
      @include breakpoint($breakpoint) {
      height: $size;
      }
    }

  }


  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $slideshow-background;

    //&:after {
    //  display: block;
    //  width: 100%;
    //  height: $slideshow-slide-overlay-height;
    //  position: absolute;
    //  top: 0;
    //  right: 0;
    //  left: 0;
    //  background: $slideshow-slide-overlay-bg;
    //  content: '';
    //}

    .slideBackground {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover";
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
      // curved bottom
      //clip-path: ellipse(150% 98% at 50% 0%);
    }

    .slideInner {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;

      align-items: center;

      @include alignment-classes();

    }

    .slideContent {
      //needed only if home has over slideshow header
      .header-is-overlay &{
        margin-top: 150px;
        margin-bottom: 50px
      }
      background-color: rgba(14, 97, 151,  .70);

      color: $slide-content-color;
      display: block;
      position: relative;
      max-width: 850px;
      padding: 3rem;
      z-index: 1;

      &.textDark {
        background-color: rgba(255, 255, 255, 0.7);
        color: $slide-content-color-dark;
      }

      .slideTitle {
        color: inherit;

        margin: 0;
        padding: 0;
        transition: transform .5s ease, opacity 0.5s ease;
        font-family: $slideshow-slide-title-family;
        opacity: 0;
        transform: translateY(4rem);

      }

      .slideCaption {
        color: inherit;
      }

    }

    &.is-selected {
      .slideTitle {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .slide:nth-child(1) {
    z-index: 2;
  }
  .flickity-enabled .slide:nth-child(1) {
    z-index: initial;
  }
}// .slideShow

// Slide show / Homepage  alerts
.marquee {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

}
.marquee .marquee-inner {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
@keyframes fade {
  0%   { opacity: 0; }
  11.11%   { opacity: 1; }
  33.33%  { opacity: 1; }
  44.44%  { opacity: 0; }
  100% { opacity: 0; }
}
.fadein p {
  opacity:0;
  animation-name: fade;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  &:hover {
    animation-play-state: paused;
  }
  position:absolute;
  left:0;
  right:0;
  top:0;
}
.fadein p:nth-child(1) { animation-delay: 0s; }
.fadein p:nth-child(2) { animation-delay: 3s; }
//.fadein p:nth-child(3) { animation-delay: 6s; }

.slideshowAlert {
  background:$slideshow-alert-background;
  color: $slideshow-alert-color;
  padding: 1rem;
  position: relative;
  .close-button{
    &:hover{

    }
  }

  &.reveal .slideshowAlert-icon{
    position:relative;
    background-color: $slideshow-alert-icon-background;
    color:$slideshow-alert-icon-color;
    text-align: center;
    border-radius: 50%;
    padding: 1.5rem;
    display: inline-block;

    span {
      width: 3rem;
      height: 3rem;
      font-size: 2.5rem;
    }
  }
}
.slideshowAlert--banner {
  //position: absolute;
  padding: .5rem;
  font-weight: bold;
  width: 100%;
  z-index: 3;

  .grid-container {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  .slideshowAlert-icon {
    position: absolute;
  }
  p {
    margin-bottom:0;
    display: inline-block;
    margin-right: 3rem;
  }

}


/*! flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}