////
/// @group components
////

/// Default Quick Stat background color.
/// @type Color
$quickstat-background: $theme-medium !default;

/// Default Quick Stat hover color.
/// @type Color
$quickstat-hover-color: $secondary-color !default;

/// Default Quickstat title color.
/// @type Color
$quickstat-color: null !default;

/// Default Quickstat title color.
/// @type Color
$quickstat-title-color: $secondary-color !default;

/// Default Quickstat title color.
/// @type Color
$quickstat-icon-color: $black !default;

/// Default Quickstat overlay background - none if null.
/// @type Null | Color
$quickstat-overlay: null !default;

/// Default Quickstat overlay opacity.
/// @type Number
$quickstat-opacity: 0.5 !default;

/// Default Quickstat padding.
/// @type Color
$quickstat-padding: null !default;

.quickStats-component{
  background-color: $quickstat-background;
  @include background-cover;
  @if $quickstat-overlay {
    &::before {
      @include full-cover;
      background: $quickstat-overlay;
      opacity: $quickstat-opacity;
      content: "";

    }
  }

   .grid-container{
    position: relative;
  }
}

.quickStat {
  text-align: center;
  padding: $quickstat-padding;
  .stat {
    font-weight: $global-weight-bold;
    font-size: 3.5rem;
    color:$quickstat-color;
    font-family: $header-font-family;
  }
  .statIcon {
    font-size: 3.5rem;
    position: relative;
    width: 92px;
    margin: 0 auto;
    .icon {
      position: relative;
      left:5px;
      color: $quickstat-icon-color;

    }

  }

  &:hover  .stat { color: $quickstat-hover-color}

  .statTitle {
    font-size: 1rem;
    margin: 1rem 0;
    color: $quickstat-title-color;
    font-style: italic;
    font-family: $serif-font-family;
  }
  .statComparison{
    //font-style: italic;
  }
}

