$checked-input-color: $secondary-color !default;
/*! ModuleBegin::forms */
.formContent{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 36px;
}
.formSubGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.formSubGroup .formGroup--medium {
  width: calc(50% - .75rem);
}
.formFieldset,.formStaticText {
  width: 100%; }
.formGroup {
  display: block;
  margin-bottom: 0.5em;
  position: relative;
  z-index: 0;
  width: 100%;
  align-self:center;}
@include breakpoint(large up){
  .formGroup {
    margin-bottom: 1em;
  }
}
fieldset.formInput-group {
  border: $fieldset-border;
  border-radius: $global-radius;
  padding: 0.75rem;
}
fieldset.formInput-group legend{  padding: 0 8px 0 5px;}

.formInput-columns { display: flex; flex-flow: row wrap;}
.formInput-columns label { flex: 0 1 auto; width: 100%; margin-bottom: 1rem}
.formInput-columns label input {
  margin-bottom: 0;
}
@media screen and (min-width: 40em)
{
  .formInput-columns.columns-auto label {
    flex: 1 1 10%;
    width: auto;
  }

  .formInput-columns.columns-2 label {
    width: 50%;
  }

  .formInput-columns.columns-3 label {
    width: 33%;
  }

  .formInput-columns.columns-4 label {
    width: 25%;
  }

  .formInput-columns.columns-5 label {
    width: 20%;
  }

}
.formGroup--aligned {
  display: flex;
  align-items:center;

}
.formGroup--aligned .formLabel {
  white-space: nowrap;
  flex: 1 1 auto;
  padding-right:.75rem;
  margin-bottom: 1rem;
}
.formGroup--checkbox.formGroup--aligned  input{ margin-bottom:0}

@media (min-width: 64em) {
  //todo use foundation mixins
  .formGroup--quarter {
    width: calc(25% - 0.75rem); }
  .formGroup--small {
    width: calc(33.333% - 0.75rem); }
  .formGroup--medium {
    width: calc(50% - 0.75rem); }
  .formGroup--large {
    width: calc(66.666% - 0.75rem); }
  .formGroup--full {
    width: 100%; } }
.formNavigation {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.button + .formNavigation-next {
  margin-left: auto; }

@import "forms/text";
.formInput[type=file]{
  @include form-element;
  height:auto;
  padding:0.25rem
}

input[type=radio], input[type=checkbox] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: $input-background;
  width: 1.5rem;
  height: 1.5rem;
  border: $input-border;

  outline: none;
}
input[type=radio] {
  border-radius: 50%;
  vertical-align: top;
}
input[type=radio]::after {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
}
input[type=radio]:checked {
  background: $checked-input-color;
  border-color: $checked-input-color;
}
input[type=radio]:checked::after {
  content: ' ';
}
input[type=checkbox] {
  vertical-align: top;
}
input[type=checkbox]::after {
  position: absolute;
  top: 50%;
  left: 5px;
  width: .6rem;
  height: 1rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-top: -0.75rem;
  color: $checked-input-color;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
  border-bottom: 3px solid;
  border-right: 3px solid;
  transform: rotate(45deg);
  box-sizing: border-box;
}

input[type=checkbox]:checked {
  border-color: $checked-input-color;
}
input[type=checkbox]:checked::after {
  content: ' ';
}

.formGroup .formLabel { font-weight:500; font-size:.9rem; }
.formLabel.formLabel--required::after {content: "*";color: red;}


/*! basic calendar popup css */
#ui-datepicker-div { background:#fff; border: 2px solid #333; }
.ui-datepicker { width:20em; display:none; }
.ui-datepicker .ui-datepicker-header { position:relative; padding:.2em 0; background:#333; color:#fff; }
.ui-datepicker .ui-datepicker-header a { color:#fff; }
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next { position:absolute; top:0; width:70px; height:1.8em; }
.ui-datepicker .ui-datepicker-prev { left:2px; text-align:left; }
.ui-datepicker .ui-datepicker-next { right:2px; text-align:right; }
.ui-datepicker .ui-datepicker-prev span { cursor:pointer; display:block; position:absolute; left:10px; top:50%; margin-top:-8px; }
.ui-datepicker .ui-datepicker-next span { cursor:pointer; display:block; position:absolute; right:10px; top:50%; margin-top:-8px; }
.ui-datepicker .ui-datepicker-title { margin:0 2.3em; line-height:1.8em; text-align:center; }
.ui-datepicker .ui-datepicker-title select { font-size:1em; margin:1px 0; }
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year { width:45%; }
.ui-datepicker table { width:100%; font-size:.9em; border-collapse:collapse; margin: 0 0 0.4em; }
.ui-datepicker th { padding: 0.7em 0.3em; text-align:center; font-weight:bold; border:0; }
.ui-datepicker td { border:0; padding:1px; }
.ui-datepicker td span, .ui-datepicker td a { display:block; padding:0.2em; text-align:center; text-decoration:none; }
.ui-datepicker .ui-datepicker-buttonpane { background-image:none; margin: 0.7em 0 0 0; padding: 0 0.2em; border-left:0; border-right:0; border-bottom:0; }
.ui-datepicker .ui-datepicker-buttonpane button { float:right; margin: 0.5em 0.2em 0.4em; cursor:pointer; padding: 0.2em 0.6em 0.3em 0.6em; width:auto; overflow:visible; }
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float:left; }
/*! ModuleEnd::forms */
