@charset "UTF-8";
/*!
 * DO NOT EDIT
 * THis file is part of an active redesign and
 * it is linked to scss files in the wd_master_theme repository
 * If you need to add styles to the redesign use an additional temporary css file and add that to the layout as well
 * Or edit the scss files and rebuild them
 *
 */
/*! -------------------------------- -------------------------------- */
/*! ---------------------------- Headings --------------------------- */
/*! -------------------------------- -------------------------------- */
h5, .h5 {
  font-weight: 400;
  font-style: italic;
  font-family: Merriweather, sans-serif; }

h2 a {
  color: inherit; }

.hollow-text {
  -webkit-text-stroke: 1px currentColor;
  -webkit-text-fill-color: transparent; }

.editorContent a:not([class]) {
  font-weight: bold; }

.logo.demo {
  display: inline-block; }

/*! -------------------------------- -------------------------------- */
/*! ---------------------------- Sections --------------------------- */
/*! -------------------------------- -------------------------------- */
#contentControl h1.pageTitle {
  display: none; }

.content {
  background-color: #fff; }

section {
  display: block;
  position: relative;
  padding-bottom: 1rem;
  box-sizing: border-box; }

.grid-container.wide {
  max-width: 85em; }

.grid-container.extra-wide {
  max-width: 120em; }

.grid-x.reversed {
  flex-direction: row-reverse; }

.forceWhite {
  color: white; }

.themeDark {
  background: #10314e;
  color: #fff; }
  .themeDark address, .themeDark p, .themeDark a:not(.button), .themeDark h1, .themeDark h2, .themeDark h3, .themeDark h4 {
    color: #fff; }

.themePrimary {
  background: #c51d24;
  color: #fff; }
  .themePrimary address, .themePrimary p, .themePrimary a:not(.button), .themePrimary h1, .themePrimary h2, .themePrimary h3, .themePrimary h4 {
    color: #fff; }

.darkBackground {
  background: #10314e; }

.themeMedium {
  background: #F5F5F5; }

.themeLight {
  background-color: #fff; }

.pad-y-none {
  padding-top: 0;
  padding-bottom: 0; }

.pad-top-none {
  padding-top: 0; }

.pad-bottom-none {
  padding-bottom: 0; }

.pad-y-none {
  padding-top: 0;
  padding-bottom: 0; }

.pad-top-none {
  padding-top: 0; }

.pad-bottom-none {
  padding-bottom: 0; }

.pad-y-none {
  padding-top: 0;
  padding-bottom: 0; }

.pad-top-none {
  padding-top: 0; }

.pad-bottom-none {
  padding-bottom: 0; }

.pad-y-small {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.pad-top-small {
  padding-top: 1rem; }

.pad-bottom-small {
  padding-bottom: 1rem; }

.pad-y-small {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.pad-top-small {
  padding-top: 1rem; }

.pad-bottom-small {
  padding-bottom: 1rem; }

.pad-y-small {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.pad-top-small {
  padding-top: 1rem; }

.pad-bottom-small {
  padding-bottom: 1rem; }

.pad-y-medium {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.pad-top-medium {
  padding-top: 3rem; }

.pad-bottom-medium {
  padding-bottom: 3rem; }

.pad-y-medium {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.pad-top-medium {
  padding-top: 3rem; }

.pad-bottom-medium {
  padding-bottom: 3rem; }

.pad-y-medium {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.pad-top-medium {
  padding-top: 3rem; }

.pad-bottom-medium {
  padding-bottom: 3rem; }

.pad-y-large {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.pad-top-large {
  padding-top: 5rem; }

.pad-bottom-large {
  padding-bottom: 5rem; }

.pad-y-large {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.pad-top-large {
  padding-top: 5rem; }

.pad-bottom-large {
  padding-bottom: 5rem; }

.pad-y-large {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.pad-top-large {
  padding-top: 5rem; }

.pad-bottom-large {
  padding-bottom: 5rem; }

.push-to-bottom {
  margin-top: auto;
  justify-self: flex-end; }

.moduleHeader {
  margin-bottom: 1.5rem; }

/*! -------------------------------- -------------------------------- */
/*! ------------------- Misc. General Site Styling ------------------ */
/*! -------------------------------- -------------------------------- */
.imageLeft {
  float: left;
  margin-right: 2rem;
  margin-bottom: 2rem; }

.imageRight {
  float: right;
  margin-left: 2rem;
  margin-bottom: 2rem; }

@media print, screen and (min-width: 40em) {
  .imageHalf {
    width: 50%; } }

.eyebrow {
  font-size: 1.125rem;
  font-style: italic;
  color: #006197;
  font-family: Merriweather, sans-serif; }

.arrowLink, .moreLink {
  color: inherit;
  font-weight: bold; }

.arrowLink::after, .moreLink::after, .button.arrow::after, .button.arrow-hover::after {
  right: 5px;
  left: auto;
  margin-left: 5px;
  transition: transform 0.2s ease;
  display: inline-block;
  border: none;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  color: inherit;
  content: '';
  height: 10px;
  width: 10px;
  transform: translateX(0rem) rotate(45deg); }

.arrowLink:hover::after, .a:hover .arrowLink::after, .moreLink:hover::after, .a:hover .moreLink::after, .button.arrow:hover::after, .a:hover .button.arrow::after, .button.arrow-hover:hover::after, .a:hover .button.arrow-hover::after {
  transform: translateX(0.5rem) rotate(45deg); }

.arrow-hover {
  padding-left: 1.5rem; }

.arrow-hover:after {
  opacity: 0;
  transform: translateX(-0.5rem) rotate(45deg); }

.arrow-hover:hover:after {
  opacity: 1; }

/*! -------------------------------------------------------------------- */
/*! Foundation Overrides                                                 */
.svgDivider {
  height: 5vw;
  width: 100%;
  position: relative; }

.hasSvg--top .grid-container {
  padding-top: calc(2rem + 5vw); }

.hasSvg--top .svgDivider {
  margin-bottom: -5vw;
  z-index: 1; }

.hasSvg--bottom {
  padding-bottom: 0; }
  .hasSvg--bottom .grid-container {
    padding-bottom: calc(2rem + 5vw); }
  .hasSvg--bottom .svgDivider {
    margin-top: -5vw;
    z-index: 1; }

/*! TinyMCE reset  - change body back to 16px from 14px ugh*/
.mce-content-body {
  font-size: 16px; }

.mce-content-body p, .mce-content-body div, .mce-content-body h1, .mce-content-body h2, .mce-content-body h3, .mce-content-body h4, .mce-content-body h5, .mce-content-body h6 {
  line-height: 1.6; }

/*! -------------------------------------------------------------------- */
/*! Framework -  Header, Footer, Content and Sidebar                     */
/*! -------------------------------------------------------------------- */
.header {
  display: block;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  z-index: 5;
  background: white;
  border-bottom: 1px solid rgba(16, 49, 78, 0.8); }
  .header.is-stuck {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3); }
  body.header-is-overlay .header {
    background: transparent;
    position: absolute;
    width: 100%;
    border-bottom: 0; }

a.logo.demo {
  background: gray;
  height: 75px;
  width: 100%; }

.header-top {
  padding: 5px 0;
  max-height: 4rem; }
  .is-stuck .header-top {
    display: none; }

@media print, screen and (max-width: 39.99875em) {
  .header-social {
    display: none; } }

.header-buttons {
  margin-left: 1rem;
  flex: 0 0 auto; }
  .header-buttons a {
    white-space: nowrap; }
  @media screen and (max-width: 640px) {
    .header-buttons a {
      font-size: 0.7rem; } }

.header-util {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 67px;
  justify-self: flex-end;
  margin-left: auto; }
  .header-util .button, .header-util .input-group {
    margin-bottom: 0; }

.header-search {
  flex: 0 0 auto;
  margin-left: 1rem; }
  .header-search .button {
    margin-bottom: 0; }
  .header-search .input-group {
    width: 175px; }
    .header-search .input-group .input-group-field {
      border: 0;
      box-shadow: none; }

.header-search-popup {
  position: absolute;
  /* top: 0; */
  width: calc(100% - 50px);
  right: 0;
  display: flex;
  align-items: center;
  background: #006197;
  margin-top: auto;
  max-width: 400px;
  padding: 2rem 2rem 1rem;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  border: 0; }

.header-search[aria-expanded="true"],
.header-search.is-active {
  background: #F5F5F5; }

.header-links.header-right {
  display: flex;
  justify-content: end;
  align-items: center; }

.header-links a {
  color: inherit;
  display: flex;
  font-size: 14px;
  align-items: center; }

.header-links a .icon {
  color: #c51d24;
  font-size: 2rem;
  margin-right: 8px; }

.header-main-container {
  background: #10314e; }

.header-main {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  max-height: 70px;
  transition: height 0.4s ease;
  width: 100%; }

.header-logo {
  flex-shrink: 0;
  margin-right: 40px;
  transition: color 0.4s ease, width 0.4s ease;
  width: 300px; }
  .is-stuck .header-logo {
    width: 250px; }
  @media screen and (max-width: 640px) {
    .header-logo {
      width: 190px;
      margin-right: 5px;
      padding-bottom: 10px; } }

.header-logo svg {
  width: 100%; }

.header-nav {
  white-space: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  height: 67px;
  max-height: 105px;
  max-width: 940px;
  position: relative;
  width: 100%; }
  .header-nav a {
    color: #454545;
    font-size: 1rem;
    font-weight: 600; }
  .header-nav li.is-overflown {
    visibility: hidden;
    pointer-events: none; }
  .header-nav > li > a {
    border-bottom: 3px solid transparent;
    color: #454545; }
  .header-nav ul li:hover > a {
    background: #F5F5F5; }
  .header-nav > li.menuItemHighlight > a, .header-nav > li:hover > a {
    border-bottom: 3px solid #c51d24; }

@media print, screen and (max-width: 63.99875em) {
  .header-nav {
    display: none; } }

.header .header-mobile-only-trigger {
  display: none;
  background-color: #10314e; }

/*! Incrementally hide the top bar items*/
@media screen and (max-width: 640px) {
  .header .header-links {
    display: none; }
  .header .header-main-container {
    display: none; }
  .header .header-mobile-only-trigger {
    display: block; } }

@media screen and (max-width: 925px) {
  .header .header-social {
    display: none; } }

@media screen and (max-width: 880px) {
  .header .header-search {
    display: none; } }

.footer {
  display: block;
  position: relative;
  padding: 0; }
  .footer {
    background: #10314e;
    color: #fff; }
  .footer address, .footer p, .footer a, .footer h1, .footer h2, .footer h3, .footer h4 {
    color: #fff; }
  .footer .footer-brand {
    font-size: 1.2em; }

.footer-main {
  padding-top: 4rem;
  padding-bottom: 2rem; }
  .footer-main a {
    color: #fff;
    font-weight: 500;
    text-decoration: underline; }
    .footer-main a:hover {
      text-decoration: underline; }

.footer-logo {
  display: block;
  width: 330px;
  padding: 0;
  margin-bottom: 2rem; }

.footer-copyright {
  padding: 1rem 0;
  font-size: 0.875rem; }
  .footer-copyright a {
    color: #fff; }
    .footer-copyright a:hover {
      text-decoration: underline; }
  .footer-copyright .accrisoft {
    color: #fff; }

.socialMenu {
  margin-bottom: 1rem; }
  .socialMenu li {
    margin-right: .5rem; }
    .socialMenu li:last-child {
      margin-right: 0; }
  .socialMenu a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    color: #c51d24;
    text-decoration: none;
    text-align: center;
    font-size: 1.5rem; }
    .socialMenu a:hover {
      text-decoration: none;
      background: #e6e6e6; }

.mobileNav-trigger {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  z-index: 2;
  color: #222222;
  background: transparent;
  width: 50px;
  margin: 0 0 0 1rem; }

.header .mobileNav-trigger {
  height: 56px;
  color: #006197;
  margin-left: auto; }

@media print, screen and (min-width: 40em) {
  .header .mobileNav-trigger {
    margin-top: auto; } }

.mobileNav-trigger {
  margin-bottom: 6px; }

.mobileNav-trigger .mobileNav-hamburger {
  margin: 0 auto;
  position: relative; }

.mobileNav-trigger .mobileNav-hamburger:before, .mobileNav-trigger .mobileNav-hamburger:after {
  position: absolute;
  content: ''; }

.mobileNav-trigger .mobileNav-hamburger, .mobileNav-trigger .mobileNav-hamburger:before, .mobileNav-trigger .mobileNav-hamburger:after {
  width: 30px;
  height: 4px;
  background-color: currentColor;
  display: block;
  border-radius: 2px;
  transition-duration: 0s;
  transition-delay: 0.2s; }

.mobileNav-trigger .mobileNav-hamburger:before {
  margin-top: -12px; }

.mobileNav-trigger .mobileNav-hamburger:after {
  margin-top: 12px; }

.mobileNav-trigger.is-active .mobileNav-hamburger,
.mobileNav-trigger[aria-expanded="true"] .mobileNav-hamburger {
  background-color: rgba(0, 0, 0, 0); }

.mobileNav-trigger .mobileNav-hamburger:before, .mobileNav-trigger .mobileNav-hamburger:after {
  transition-property: margin, transform;
  transition-duration: 0.2s;
  transition-delay: 0.2s, 0s; }

.mobileNav-trigger[aria-expanded="true"] .mobileNav-hamburger:before, .mobileNav-trigger[aria-expanded="true"] .mobileNav-hamburger:after,
.mobileNav-trigger.is-active .mobileNav-hamburger:before, .mobileNav-trigger.is-active .mobileNav-hamburger:after {
  margin-top: 0;
  transition-delay: 0s, 0.2s; }

.mobileNav-trigger.is-active .mobileNav-hamburger:before,
.mobileNav-trigger[aria-expanded="true"] .mobileNav-hamburger:before {
  transform: rotate(45deg); }

.mobileNav-trigger.is-active .mobileNav-hamburger:after,
.mobileNav-trigger[aria-expanded="true"] .mobileNav-hamburger:after {
  transform: rotate(-45deg); }

@media screen and (min-width: 40em) {
  .mobileNav-inner .mobileNav-trigger {
    display: flex; } }

@media screen and (min-width: 64em) {
  .mobileNav-trigger {
    display: none; } }

.header.has-mobile-trigger .mobileNav-trigger {
  display: flex; }

.mobileNav {
  height: calc(100%);
  left: 0;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 9; }

.mobileNav-inner {
  background: white;
  display: block;
  height: 100%;
  max-width: 100%;
  overflow-y: scroll;
  padding: 0 0;
  position: absolute;
  right: 0;
  transform: translate(100%, 0);
  transition: transform 0.5s ease 0.3s, visibility 0.5s ease;
  visibility: hidden;
  width: calc(100% - 76px); }

.mobileNav-inner .mobileNav-trigger {
  position: absolute;
  right: 1rem; }

.mobileNav-menu > li {
  border-top: 1px solid #f2f2f2;
  position: relative; }

.mobileNav-menu a {
  color: #222222;
  font-weight: bold;
  font-size: 1rem; }

.mobileNav-menu > li > a {
  position: relative;
  padding: 1rem;
  font-weight: bold; }

.mobileNav-menu > li > ul > li {
  position: relative;
  align-items: stretch;
  display: flex;
  flex-flow: column;
  justify-content: flex-start; }

.mobileNav-menu ul ul a {
  color: #818181; }

.mobileNav-menu ul ul > li {
  padding: 0; }

.mobileNav-menu > li > ul {
  display: none; }

.mobileNav-actions {
  min-height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 1rem; }

.mobileNav-login .button {
  margin: 0; }

.mobileNav-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background 0.5s ease;
  width: 100%; }

.mobileNav-secondary {
  background: #10314e;
  padding: 1rem 1.5rem;
  margin-top: auto; }
  .mobileNav-secondary .socialMenu {
    margin-bottom: 0; }
    .mobileNav-secondary .socialMenu .menu {
      justify-content: center; }
  .mobileNav-secondary .socialMenu a {
    font-size: 1.5rem; }

@media screen and (min-width: 40em) {
  .mobileNav {
    height: 100%;
    z-index: 11; }
  .mobileNav-inner {
    width: 335px; }
  .mobileNav-inner .mobileNav-trigger {
    display: flex; } }

.header.has-mobile-trigger .mobileNav-trigger {
  display: flex; }

@media screen and (min-width: 900px) {
  .mobileNav-inner {
    padding-top: 0px; }
  .is-header-stuck .mobileNav-inner {
    padding-top: 0; } }

.is-mobilenav-open {
  overflow-y: hidden; }

.is-mobilenav-open .mobileNav {
  pointer-events: all; }

.is-mobilenav-open .mobileNav-inner {
  transform: translate(0, 0);
  visibility: visible; }

.is-mobilenav-open .mobileNav-overlay {
  background: rgba(0, 0, 0, 0.15); }

.modulePage {
  padding-bottom: 3rem;
  padding-top: 2rem; }

#interiorHeaderSource, #interiorHeaderOverride {
  display: none; }

.content-body {
  flex: 1 1 20px;
  width: auto; }

.content-sidebar {
  flex: 1 1 auto;
  width: 100%;
  padding-top: 1rem; }

@media screen and (min-width: 1024px) {
  .content-sidebar {
    width: 290px;
    margin-right: 36px;
    flex: 0 0 auto;
    order: -1;
    display: none; }
  .content-sidebar.is-visible {
    display: block; } }

.sidebarItem {
  margin-bottom: 2rem; }

.sidebarItem--boxed {
  padding: 2rem;
  background: #F5F5F5; }

/*! Side Menu */
.sideMenu-title {
  margin-left: 1rem; }

.sideMenu {
  padding: 2rem; }

.sideMenu-nav .menu a {
  color: #222222;
  font-size: 1rem;
  font-weight: bold; }
  .sideMenu-nav .menu a:hover, .sideMenu-nav .menu a.current {
    color: #818181; }

.sideMenu-nav > .menu ul a {
  padding-left: 1.5rem; }

.sideMenu-nav > .menu ul ul a {
  padding-left: 2.5rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded=true] > a::after {
  transform: none;
  content: "–"; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  border: 0;
  content: "+";
  right: unset;
  left: 0;
  margin-top: -8px; }

/*! -------------------------------------------------------------------- */
/*! Reusable Styles                                                      */
/*! -------------------------------------------------------------------- */
/*! Expandies / Collapsible Content */
.collapsibleHeader {
  text-transform: none;
  font-size: 1.125rem;
  position: relative;
  padding: .4rem .4rem .4rem 2.75rem;
  cursor: pointer;
  border-bottom: 1px solid #E2E4EA;
  background: #F5F5F5;
  font-family: inherit;
  color: inherit; }

.collapsibleHeader::before {
  position: absolute;
  color: #c51d24;
  line-height: 1em;
  text-align: center;
  transition: transform .2s ease;
  left: .5rem;
  content: '\002B';
  border-radius: 50%;
  padding-left: 1px;
  width: 1em;
  height: 1.5em;
  top: 3px;
  font-size: 1.5em; }

.collapsibleHeader.active {
  border-bottom-color: transparent; }

.collapsibleHeader.active::before {
  content: '\2212';
  padding-left: 3px;
  padding-bottom: 2px; }

.collapsibleContent {
  border-bottom: 1px solid #E2E4EA;
  overflow: hidden;
  display: none; }

.no-js .collapsibleContent {
  display: block; }

/*! -------------------------------------------------------------------- */
/*! Components                                                           */
/*! -------------------------------------------------------------------- */
/*! ----- One offs and module components  ------ */
/*! ----- Slideshow ------ */
.slideshow {
  display: block;
  position: relative;
  margin: 0;
  padding: 0; }
  .slideshow .slides {
    display: block;
    position: relative;
    z-index: 1;
    height: 500px; }
    @media print, screen and (min-width: 64em) {
      .slideshow .slides {
        height: 575px; } }
    @media screen and (min-width: 90em) {
      .slideshow .slides {
        height: 700px; } }
  .slideshow .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #10314e; }
    .slideshow .slide .slideBackground {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover";
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0; }
    .slideshow .slide .slideInner {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center; }
      .slideshow .slide .slideInner.align-left {
        justify-content: flex-start;
        text-align: left; }
      .slideshow .slide .slideInner.align-right {
        justify-content: flex-end;
        text-align: right; }
      .slideshow .slide .slideInner.align-center {
        justify-content: center;
        text-align: center; }
      .slideshow .slide .slideInner.align-top {
        align-items: flex-start; }
      .slideshow .slide .slideInner.align-middle {
        align-items: center; }
      .slideshow .slide .slideInner.align-bottom {
        align-items: flex-end; }
    .slideshow .slide .slideContent {
      background-color: rgba(14, 97, 151, 0.7);
      color: #FFF;
      display: block;
      position: relative;
      max-width: 850px;
      padding: 3rem;
      z-index: 1; }
      .header-is-overlay .slideshow .slide .slideContent {
        margin-top: 150px;
        margin-bottom: 50px; }
      .slideshow .slide .slideContent.textDark {
        background-color: rgba(255, 255, 255, 0.7);
        color: #222222; }
      .slideshow .slide .slideContent .slideTitle {
        color: inherit;
        margin: 0;
        padding: 0;
        transition: transform .5s ease, opacity 0.5s ease;
        opacity: 0;
        transform: translateY(4rem); }
      .slideshow .slide .slideContent .slideCaption {
        color: inherit; }
    .slideshow .slide.is-selected .slideTitle {
      opacity: 1;
      transform: translateY(0); }
  .slideshow .slide:nth-child(1) {
    z-index: 2; }
  .slideshow .flickity-enabled .slide:nth-child(1) {
    z-index: initial; }

.marquee {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box; }

.marquee .marquee-inner {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite; }
  .marquee .marquee-inner:hover {
    animation-play-state: paused; }

@keyframes marquee {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-100%, 0); } }

@keyframes fade {
  0% {
    opacity: 0; }
  11.11% {
    opacity: 1; }
  33.33% {
    opacity: 1; }
  44.44% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.fadein p {
  opacity: 0;
  animation-name: fade;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0; }
  .fadein p:hover {
    animation-play-state: paused; }

.fadein p:nth-child(1) {
  animation-delay: 0s; }

.fadein p:nth-child(2) {
  animation-delay: 3s; }

.slideshowAlert {
  background: #006197;
  color: #F5F5F5;
  padding: 1rem;
  position: relative; }
  .slideshowAlert.reveal .slideshowAlert-icon {
    position: relative;
    background-color: #c51d24;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    padding: 1.5rem;
    display: inline-block; }
    .slideshowAlert.reveal .slideshowAlert-icon span {
      width: 3rem;
      height: 3rem;
      font-size: 2.5rem; }

.slideshowAlert--banner {
  padding: .5rem;
  font-weight: bold;
  width: 100%;
  z-index: 3; }
  .slideshowAlert--banner .grid-container {
    white-space: nowrap;
    overflow: hidden;
    position: relative; }
  .slideshowAlert--banner .slideshowAlert-icon {
    position: absolute; }
  .slideshowAlert--banner p {
    margin-bottom: 0;
    display: inline-block;
    margin-right: 3rem; }

/*! flickity-fade */
.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0; }

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1; }

/*! ----- Interior Header Banner ----- */
/* ---------------------------------------------------------------------------*/
/* Advanced Overlay settings - not used by default                            */
/* By default image overlays use background inherit and generic theme classes */
/* -------------------------------------------------------------------------- */
.pageHeader {
  --header-overlay-color: var(--theme-background, #006197);
  --header-overlay-direction: to right;
  --header-overlay-opacity: 0.6;
  height: auto;
  padding: 0;
  display: block;
  position: relative;
  background: #006197;
  color: #fff; }
  .pageHeader .eyebrow {
    color: inherit; }
  @media print, screen and (min-width: 40em) {
    .pageHeader {
      height: 250px; } }
  .pageHeader--hasImage {
    color: #fff; }
    @media print, screen and (min-width: 40em) {
      .pageHeader--hasImage {
        height: 320px; } }
  .pageHeader--alt {
    background: #F5F5F5;
    color: #10314e; }
  .pageHeader .pageHeader-inner {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: flex-start; }
    .pageHeader .pageHeader-inner.align-left {
      justify-content: flex-start;
      text-align: left; }
    .pageHeader .pageHeader-inner.align-right {
      justify-content: flex-end;
      text-align: right; }
    .pageHeader .pageHeader-inner.align-center {
      justify-content: center;
      text-align: center; }
    .pageHeader .pageHeader-inner.align-top {
      align-items: flex-start; }
    .pageHeader .pageHeader-inner.align-middle {
      align-items: center; }
    .pageHeader .pageHeader-inner.align-bottom {
      align-items: flex-end; }
    .pageHeader .pageHeader-inner .pageHeader-cta {
      margin-left: auto; }
    .pageHeader .pageHeader-inner.align-center .pageHeader-cta, .pageHeader .pageHeader-inner.align-right .pageHeader-cta {
      margin-left: 0;
      flex: 1 0 100%; }
  .pageHeader .pageHeader-background {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: inherit; }
    .pageHeader .pageHeader-background img {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover";
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0; }
    .pageHeader .pageHeader-background::after {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      content: unset; }
    .pageHeader .pageHeader-background.showOverlay::after {
      content: ""; }
    .pageHeader .pageHeader-background.gradientOverlay::after {
      background-image: linear-gradient(var(--header-overlay-direction, to right), var(--header-overlay-color), var(--header-overlay-to-color, transparent)); }
    .pageHeader .pageHeader-background.fullOverlay::after {
      background-color: inherit;
      opacity: var(--header-overlay-opacity, 0.6); }
    .pageHeader .pageHeader-background.noImage {
      display: none; }
  .pageHeader .pageHeader-content {
    transition: opacity 0.8s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .pageHeader .pageHeader-content.loading {
      opacity: 0; }
  .pageHeader h1 {
    color: inherit;
    line-height: 1; }

@media screen and (max-width: 1024px) {
  .pageHeader--splitImage {
    height: auto; }
  .pageHeader--splitImage .pageHeader-content {
    padding-bottom: 200px; }
  .pageHeader--splitImage .pageHeader-background {
    height: 200px;
    bottom: 0; } }

@media screen and (min-width: 1024px) {
  .pageHeader--splitImage .pageHeader-content {
    width: 50%; }
  .pageHeader--splitImage .pageHeader-background {
    width: 50%;
    height: 100%;
    left: unset;
    right: 0; } }

/*! ----- Slideshow ------ */
.carousel-wrapper {
  margin-bottom: 2rem; }
  .carousel-wrapper.flickity-enabled {
    display: block; }
  .carousel-wrapper.small-up-2 .flickity-slider > .cell {
    width: 50%; }
  .carousel-wrapper.small-up-3 .flickity-slider > .cell {
    width: 33.33333%; }
  .carousel-wrapper.small-up-4 .flickity-slider > .cell {
    width: 25%; }
  .carousel-wrapper.small-up-5 .flickity-slider > .cell {
    width: 20%; }
  .carousel-wrapper.flickity-enabled .cell {
    width: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .carousel-wrapper.medium-up-2 .flickity-slider > .cell {
      width: 50%; }
    .carousel-wrapper.medium-up-3 .flickity-slider > .cell {
      width: 33.33333%; }
    .carousel-wrapper.medium-up-4 .flickity-slider > .cell {
      width: 25%; }
    .carousel-wrapper.medium-up-5 .flickity-slider > .cell {
      width: 20%; }
    .carousel-wrapper.flickity-enabled .cell {
      width: 100%;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }
  @media print, screen and (min-width: 64em) {
    .carousel-wrapper.large-up-2 .flickity-slider > .cell {
      width: 50%; }
    .carousel-wrapper.large-up-3 .flickity-slider > .cell {
      width: 33.33333%; }
    .carousel-wrapper.large-up-4 .flickity-slider > .cell {
      width: 25%; }
    .carousel-wrapper.large-up-5 .flickity-slider > .cell {
      width: 20%; } }
  @media screen and (min-width: 90em) {
    .cards-slider .carousel-wrapper .flickity-prev-next-button.previous {
      left: -70px; }
    .cards-slider .carousel-wrapper .flickity-prev-next-button.next {
      right: -70px; } }

/*! ----- Home Partners ----- */
.partnersComponent .carousel-wrapper {
  margin-bottom: 0; }
  @media screen and (max-width: 1300px) {
    .partnersComponent .carousel-wrapper {
      width: calc(100% - 120px);
      margin: 0 auto; } }
  .partnersComponent .carousel-wrapper .flickity-prev-next-button {
    width: 30px;
    height: 30px;
    color: white;
    background-color: transparent; }
    .partnersComponent .carousel-wrapper .flickity-prev-next-button:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.7); }
  .partnersComponent .carousel-wrapper .flickity-prev-next-button.previous {
    left: -30px; }
  .partnersComponent .carousel-wrapper .flickity-prev-next-button.next {
    right: -30px; }

.partnersComponent .carousel-wrapper.flickity-enabled {
  display: block; }

.partnersComponent .partner {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  text-align: center;
  width: 195px;
  height: 175px;
  margin: 1rem; }
  .partnersComponent .partner .partnerType {
    font-style: italic;
    color: #c51d24;
    font-size: .9rem;
    font-weight: 700;
    padding: 0.5rem;
    text-align: center; }
  .partnersComponent .partner .partnerImage {
    justify-self: flex-end;
    margin: auto;
    background-size: contain;
    height: calc(100% - 2rem);
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex; }
    .partnersComponent .partner .partnerImage img {
      width: 175px;
      height: auto;
      object-fit: contain; }

/*! ----- Quicklinks Component ----- */
.quicklinks {
  justify-content: space-around;
  align-items: stretch;
  color: #222222;
  border: 5px solid white; }

.quicklink {
  color: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  border: 5px solid white;
  padding: .75rem;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.36, 0.53, 0.33, 1.01); }
  .solid .quicklink:hover, .solid .quicklink:focus {
    box-shadow: inset 0px 0px 0px 2px white; }
  .solid .quicklink:nth-child(3n + 1) {
    background-color: #006197;
    color: #fff; }
  .solid .quicklink:nth-child(3n + 2) {
    background-color: #c51d24;
    color: #fff; }
  .solid .quicklink:nth-child(3n + 3) {
    background-color: #10314e;
    color: #fff; }
  .bordered .quicklink:hover, .bordered .quicklink:focus {
    color: inherit;
    background: #F5F5F5; }
  .bordered .quicklink:nth-child(3n + 1) {
    box-shadow: inset 0px 0px 0px 1px #e6e6e6, inset 0px -8px 0px #006197; }
  .bordered .quicklink:nth-child(3n + 2) {
    box-shadow: inset 0px 0px 0px 1px #e6e6e6, inset 0px -8px 0px #c51d24; }
  .bordered .quicklink:nth-child(3n + 3) {
    box-shadow: inset 0px 0px 0px 1px #e6e6e6, inset 0px -8px 0px #10314e; }
  @media print, screen and (min-width: 40em) {
    .quicklink {
      flex: 1 1 auto;
      width: auto;
      padding: 1rem 1rem 1rem 1rem; } }

.quicklink-icon {
  color: inherit;
  width: auto;
  font-size: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  @media print, screen and (min-width: 40em) {
    .quicklink-icon {
      font-size: 3rem; } }

.quicklink .quicklink-title {
  font-size: 1.5rem; }

.quicklink .quicklink-content {
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  z-index: 1; }

/*! ----- Cards ----- */
.card-image {
  overflow: hidden;
  height: 0;
  position: relative;
  padding-bottom: 60%;
  background-color: #c51d24;
  background-position: center center;
  background-size: cover; }
  .card-image.tall {
    padding-bottom: 110%; }
  .card-image img {
    position: absolute;
    bottom: 0;
    width: 100%; }

.cards-slider a, .cards-slider a:hover {
  color: inherit; }

@media print, screen and (max-width: 63.99875em) {
  .cards-slider .carousel-cell {
    max-width: 270px; } }

.card-section {
  transition: all .4s ease-out; }

.card--no-shadow {
  box-shadow: none; }

.card--show-more:hover .card-section {
  transform: translateY(-8px); }

.card p.card-action-text {
  opacity: 0;
  font-weight: bold;
  transition: all .4s ease-out;
  margin-top: auto;
  position: relative;
  height: 0; }

.card--show-more:hover p.card-action-text {
  opacity: 1;
  transform: translateY(-8px); }

/*! ----- Testimonial Component ----- */
.testimonial-component .grid-container {
  padding: 0; }

.testimonial-component .splitContent-column {
  border: 0;
  min-height: auto; }

.testimonial-component .splitContent-columnInner {
  width: 100%; }

@media print, screen and (min-width: 64em) {
  .testimonial-component .splitContent-right {
    flex-grow: 2; }
    .testimonial-component .splitContent-right .splitContent-columnInner {
      width: 800px;
      max-width: 800px; } }

.testimonial-component.testimonial-component--cards .testimonial-slider {
  margin: 0 auto; }

.testimonial-component.testimonial-component--cards .testimonial-image {
  width: 100px;
  height: 100px;
  margin: 1.5rem auto 0 auto;
  z-index: 1;
  position: absolute;
  top: 0;
  box-shadow: rgba(0, 2, 8, 0.6);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.testimonial-component.testimonial-component--cards .testimonial-slide {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  box-sizing: border-box; }
  @media screen and (max-width: 40em) {
    .testimonial-component.testimonial-component--cards .testimonial-slide {
      width: 100%; } }
  @media screen and (max-width: 60em) {
    .testimonial-component.testimonial-component--cards .testimonial-slide {
      width: 50%; } }
  .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-card-text {
    height: 100%;
    margin-top: 2rem;
    z-index: 0;
    padding: calc(50px + 2rem) 1rem 1rem 1rem;
    background: #fff;
    position: relative;
    border-radius: 6px; }
    .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-card-text p {
      margin: 0;
      padding: 0; }
    .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-card-text:before, .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-card-text:after {
      display: block;
      content: '"';
      width: auto;
      height: 3.5rem;
      color: #c51d24;
      font-size: 3.5rem;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      margin: 0;
      padding: 0;
      position: absolute; }
    .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-card-text:before {
      top: 70px;
      left: 1rem; }
    .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-card-text:after {
      bottom: 0rem;
      right: 1rem; }
  .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-citation {
    margin: 0 0 1rem 0;
    text-align: center; }
    .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-citation h4 {
      margin-bottom: 0; }
  .testimonial-component.testimonial-component--cards .testimonial-slide .testimonial-text {
    text-align: center; }

.testimonial-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3);
  margin: 1rem 0 2rem auto; }

.testimonial-slider {
  padding: 0 56px; }

.testimonial-slide {
  position: absolute; }

.testimonial-citation {
  margin: 2rem 0;
  text-align: right; }

/*! ----- Photo Boxes - slide out photo quicklinks ------ */
.photoBoxes {
  justify-content: center; }

.photoBox {
  height: 378px;
  max-width: 800px;
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem; }
  .photoBox .photoBoxBg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
  .photoBox:not(.cell) {
    width: 384px;
    flex: 1 1 auto;
    margin: 1rem; }
  .photoBox .photoBox-content {
    display: flex;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
    position: relative; }
    .photoBox .photoBox-content, .photoBox .photoBox-content .title, .photoBox .photoBox-content .photoBox-action:not(.button) {
      transition: all 0.3s cubic-bezier(0.36, 0.53, 0.33, 1.01);
      color: inherit; }
    .photoBox .photoBox-content .desc {
      max-height: calc(100% - 106px);
      overflow: hidden; }
  .photoBox .photoBox-drawer {
    height: 100%;
    width: 100%;
    background-color: #fff;
    color: #222222;
    position: absolute;
    transition: all 0.3s cubic-bezier(0.36, 0.53, 0.33, 1.01); }
  .photoBox .photoBox-drawer.vertical {
    height: auto;
    transform: translateY(calc(100% - 166px));
    padding-top: 25px;
    bottom: 0;
    min-height: 166px; }
  .photoBox .photoBox-drawer.horizontal {
    width: 90%;
    right: calc(100% - 50px);
    padding-right: 25px; }
    .photoBox .photoBox-drawer.horizontal .photoBox-content {
      justify-content: center; }
  .photoBox .photoBox-drawerCopy {
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.36, 0.53, 0.33, 1.01); }
  .photoBox:hover .photoBox-drawer.vertical, .photoBox.active .photoBox-drawer.vertical {
    transform: translateY(0); }
  .photoBox:hover .photoBox-drawer.horizontal, .photoBox.active .photoBox-drawer.horizontal {
    right: 10%; }
  .photoBox:hover .photoBox-drawerCopy, .photoBox.active .photoBox-drawerCopy {
    opacity: 1; }
  @media screen and (max-width: 46.875em) {
    .photoBox .photoBox-drawer.vertical {
      transform: translateY(0); }
    .photoBox .photoBox-drawerCopy {
      opacity: 1; } }
  .photoBox .horizontal .photoBox-drawerTab {
    right: -25px;
    top: calc(50% - 25px);
    left: unset; }
  .photoBox .photoBox-drawerTab {
    background: #c51d24;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    left: calc(50% - 25px);
    border-radius: 50%;
    color: #fff;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase; }
    .photoBox .photoBox-drawerTab .fa, .photoBox .photoBox-drawerTab .icon {
      font-size: 1.5rem; }
    .photoBox .photoBox-drawerTab .month {
      font-size: 0.75rem;
      display: block; }

.photoBox .photoBox-drawer.vertical .photoBox-content.left {
  align-items: flex-start;
  text-align: left; }

.photoBox .photoBox-drawer.vertical .photoBox-content.center {
  align-items: center;
  text-align: center; }

.stacked .photoBox {
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: stretch; }

.stacked .photoBox .photoBoxBg,
.stacked .photoBox .photoBox-drawer {
  position: relative;
  transform: none; }

.stacked .photoBoxBg {
  height: 0;
  padding-bottom: 75%;
  width: calc(100% - 2rem);
  margin: auto;
  background-size: contain; }

.stacked .photoBox .photoBox-drawerTab {
  display: none; }

.stacked .photoBox-drawer {
  flex-grow: 1; }

.stacked .photoBox .photoBox-drawerCopy {
  opacity: 1;
  margin-top: auto; }

.stacked .photoBox-action {
  margin-bottom: 1rem;
  display: inline-block; }

/*! ----- Quick Stats ------ */
.quickStats-component {
  background-color: #F5F5F5;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }
  .quickStats-component .grid-container {
    position: relative; }

.quickStat {
  text-align: center; }
  .quickStat .stat {
    font-weight: bold;
    font-size: 3.5rem;
    color: #c51d24;
    font-family: "Poppins", sans-serif; }
  .quickStat .statIcon {
    font-size: 3.5rem;
    position: relative;
    width: 92px;
    margin: 0 auto; }
    .quickStat .statIcon .icon {
      position: relative;
      left: 5px;
      color: #222222; }
  .quickStat:hover .stat {
    color: #006197; }
  .quickStat .statTitle {
    font-size: 1rem;
    margin: 1rem 0;
    color: #006197;
    font-style: italic;
    font-family: Merriweather, sans-serif; }

/*! ----- CTA Component ------ */
.spanningCta-title, .spanningCta-action {
  text-align: center; }

@media print, screen and (min-width: 40em) {
  .spanningCta-title {
    text-align: left; }
  .spanningCta-action {
    text-align: right; } }

/*! ----- Multi Column  ------ */
@media print, screen and (min-width: 40em) {
  .col1-39, .col2-93 {
    width: 25%; }
  .col2-39, .col1-93 {
    width: 75%; }
  .col1-57, .col2-75 {
    width: 41.66667%; }
  .col2-57, .col1-75 {
    width: 58.33333%; }
  .col1-48, .col2-84 {
    width: 33.33333%; }
  .col2-48, .col1-84 {
    width: 66.66667%; } }

/*! ----- Split Media / Text  ------ */
.splitContent-component {
  position: relative; }

.splitContent {
  align-items: center;
  position: relative; }

.splitContent-media {
  height: 100%; }

.splitContent {
  align-items: center;
  position: relative; }

.splitContent-media {
  height: 100%; }

.columnMedia {
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.columnMedia--left {
  margin-right: 3rem; }

.columnMedia--right {
  margin-left: 0; }
  @media screen and (min-width: 60em) {
    .columnMedia--right {
      margin-left: 3rem; } }

.splitContent-column {
  flex: 1 0 100%;
  position: relative;
  padding: 4rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 450px;
  background-size: cover;
  background-position: center center;
  border: 10px solid white; }
  .splitContent-column.middle {
    justify-content: center; }
  .splitContent-column.bottom {
    justify-content: flex-end; }
  .hasSvg--bottom .splitContent-column {
    padding-bottom: calc(2rem + 5vw); }
  .hasSvg--top .splitContent-column {
    padding-top: calc(2rem + 5vw); }
  .splitContent-column::before {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 1;
    content: ""; }
  .splitContent-column.has-image::before {
    opacity: .75;
    background-color: inherit; }

.splitContent-columnInner {
  margin: 0 auto;
  padding-right: .975rem;
  padding-left: .975rem;
  position: relative;
  z-index: 1; }
  .splitContent-columnInner > * {
    color: inherit; }

@media print, screen and (min-width: 64em) {
  .splitContent-column {
    flex: 1 1 0px; }
  .splitContent-column.splitContent-center {
    flex: 0 0 auto;
    width: auto; }
  .splitContent-column .splitContent-columnInner {
    max-width: 568px; }
  .splitContent-left, .splitContent-center {
    border-right: 0; }
  .splitContent-left .splitContent-columnInner {
    margin-right: 0; }
  .splitContent-right .splitContent-columnInner {
    margin-left: 0; } }

.splitContent-component.splitContent-component--dual-media .editorContent .columnContent {
  position: relative; }

.splitContent-component.splitContent-component--dual-media .columnMedia {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center; }
  @media screen and (min-width: 60em) {
    .splitContent-component.splitContent-component--dual-media .columnMedia {
      display: block;
      min-height: 300px; } }
  .splitContent-component.splitContent-component--dual-media .columnMedia img {
    display: block;
    position: relative;
    max-width: 350px;
    margin: 1rem auto; }
  @media screen and (min-width: 60em) {
    .splitContent-component.splitContent-component--dual-media .columnMedia img {
      position: absolute;
      margin: 0 auto; }
    .splitContent-component.splitContent-component--dual-media .columnMedia img:nth-child(1) {
      top: -2.5rem;
      left: 0; }
    .splitContent-component.splitContent-component--dual-media .columnMedia img:nth-child(2) {
      bottom: -2.5rem;
      right: 0; } }

/*! ----- Pop up Alert Module  ------ */
.corner-box {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  opacity: 0;
  display: none; }

.corner-box.box-left {
  right: auto;
  left: 15px; }

.corner-box-header {
  padding: 10px 15px;
  transition: all .3s ease;
  font-weight: bold;
  font-size: 1.25rem;
  cursor: pointer; }
  .corner-box-header p {
    margin: 0;
    color: inherit;
    display: inline-block; }

.corner-box-header img {
  width: 20px;
  margin: -2px 3px 0 0; }

.corner-box-header img.no-title {
  margin: -2px 0 0; }

.corner-box-main {
  border: 2px solid #fff;
  border-radius: 15px;
  transition: all .3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); }

.corner-box-content {
  display: none;
  width: 340px;
  max-height: 445px;
  overflow-y: auto;
  color: #777; }

.corner-box-content-inner {
  width: auto;
  font-size: 14px;
  background: #fff;
  margin: 0 10px 10px;
  padding: 1rem;
  border-radius: 4px; }

.corner-box.open .corner-box-header {
  border-radius: 15px 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.corner-box.open .corner-box-header:after {
  display: inline;
  width: 0;
  height: 0;
  border: inset 10px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: currentColor transparent transparent;
  right: 16px;
  left: auto; }

.corner-box.open .corner-box-content {
  border-radius: 0 0 15px 15px; }

@media only screen and (max-width: 370px) {
  .corner-box .corner-box-content {
    width: 100%; }
  .corner-box.open {
    right: 15px;
    left: 15px; } }

@media only screen and (max-height: 660px) {
  .corner-box .corner-box-content {
    max-height: 320px; } }

@media only screen and (max-height: 530px) {
  .corner-box .corner-box-content {
    max-height: 220px; } }

@media only screen and (max-height: 430px) {
  .corner-box .corner-box-content {
    max-height: 180px; } }

@media only screen and (max-height: 360px) {
  .corner-box .corner-box-content {
    max-height: 120px; } }

.popup {
  padding: 0; }

.popup-image {
  background-size: cover;
  background-position: center center;
  min-height: 50vh; }

@media print, screen and (min-width: 40em) {
  .popup-image {
    min-height: 200px; } }

.popup-text {
  padding: 1rem; }

.theme-primary {
  --theme-background: #c51d24;
  background-color: #c51d24 !important;
  color: #fff !important; }
  .theme-primary.a:hover, .theme-primary.a:focus {
    background-color: #e23940; }

.theme-secondary {
  --theme-background: #006197;
  background-color: #006197 !important;
  color: #fff !important; }
  .theme-secondary.a:hover, .theme-secondary.a:focus {
    background-color: #004e79; }

.theme-dark {
  --theme-background: #10314e;
  background-color: #10314e !important;
  color: #fff !important; }
  .theme-dark.a:hover, .theme-dark.a:focus {
    background-color: #0d273e; }

.theme-medium {
  --theme-background: #F5F5F5;
  background-color: #F5F5F5 !important;
  color: #222222 !important; }
  .theme-medium.a:hover, .theme-medium.a:focus {
    background-color: #f7f7f7; }

.theme-light {
  --theme-background: #ffffff;
  background-color: #ffffff !important;
  color: #222222 !important; }
  .theme-light.a:hover, .theme-light.a:focus {
    background-color: white; }

.theme-light-gray {
  --theme-background: #F5F5F5;
  background-color: #F5F5F5 !important;
  color: #222222 !important; }
  .theme-light-gray.a:hover, .theme-light-gray.a:focus {
    background-color: #f7f7f7; }

.theme-medium-gray {
  --theme-background: #cacaca;
  background-color: #cacaca !important;
  color: #222222 !important; }
  .theme-medium-gray.a:hover, .theme-medium-gray.a:focus {
    background-color: #d5d5d5; }

.theme-dark-gray {
  --theme-background: #818181;
  background-color: #818181 !important;
  color: #fff !important; }
  .theme-dark-gray.a:hover, .theme-dark-gray.a:focus {
    background-color: #9a9a9a; }

.theme-darker-gray {
  --theme-background: #3e3e3e;
  background-color: #3e3e3e !important;
  color: #fff !important; }
  .theme-darker-gray.a:hover, .theme-darker-gray.a:focus {
    background-color: #323232; }

.theme-black {
  --theme-background: #000;
  background-color: #000 !important;
  color: #fff !important; }
  .theme-black.a:hover, .theme-black.a:focus {
    background-color: black; }

/*! ----- Traditional Freedom Module Styles ------ */
/*! ---------------General Module Styles--------------- */
#editAnywhere {
  display: none; }

.lister-striped .lister-item {
  padding: 1rem; }

.lister-striped .lister-item:nth-child(odd) {
  background-color: #F5F5F5; }

.lister-border-bottom .lister-item {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); }

.lister-item {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  clear: both;
  margin: 1em 0 .5em;
  padding-bottom: 1em;
  padding-top: 1rem; }

.lister-title a {
  color: inherit; }

.lister-image {
  max-width: 400px;
  width: 300px;
  flex: 0 1 auto;
  margin-right: 2rem;
  text-align: center; }

.lister-date {
  color: #818181; }

.lister-content {
  flex: 1 1 50%;
  position: relative; }

.lister-dateIcon {
  position: relative;
  margin: 1rem 1rem 2rem 1rem;
  align-self: flex-start;
  background: #222222;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1;
  padding: 1rem;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  color: white; }
  .lister-dateIcon .day {
    font-size: 1.5rem;
    font-weight: bold; }

.lister-item--boxed {
  padding: 1rem;
  background: #F5F5F5; }

.lister-badge {
  position: absolute;
  top: 0;
  padding: 10px 20px;
  line-height: 1;
  border-radius: 4px;
  text-transform: none;
  font-size: 1rem;
  background: #006197;
  font-weight: 600;
  color: white; }

.lister-item--boxed .lister-badge {
  right: -1rem;
  border-radius: 4px 0 0 4px; }

.lister-cornerBadge {
  padding-top: unset; }

.lister-cornerBadge::before {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 0;
  height: 0;
  border: 40px solid #c51d24;
  border-right-color: transparent;
  border-bottom-color: transparent;
  overflow: visible;
  content: ""; }

.lister-action {
  align-self: center;
  padding-left: 1rem; }

.lister-action .arrowLink {
  color: #818181; }

.cardGridLister .lister-title {
  font-size: 1.125rem;
  font-weight: bold; }

.cardGridLister .gridItem {
  margin-bottom: 1rem; }

.cardGridLister .cell .card {
  height: 100%; }

.mini-lister .lister-item {
  border: 0; }

.lister-search {
  align-items: flex-end; }

.lister-searchLabel {
  margin-bottom: 1rem; }

.lister-searchItem {
  margin-right: 1rem; }

.lister-subHeader {
  margin: 2rem 0; }

/*! common elements across modules */
.label {
  font-weight: bold;
  margin-right: 10px; }

.date {
  font-weight: bold; }

.imageLink {
  float: left; }

.category {
  color: #006197;
  text-transform: uppercase;
  font-weight: bold; }

.tags a {
  background: #F5F5F5;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #484848;
  display: inline-block;
  padding: 3px 6px;
  margin-right: 2px; }
  .tags a:hover {
    color: #222222; }

.tagCloud {
  margin-bottom: 2rem; }

.tagCloudBox {
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 12px; }

.tagCloud a {
  margin: 2px; }
  .tagCloud a:hover {
    color: #222222; }

.utilityLinks {
  margin-top: 25px; }

.comments {
  margin-top: 12px; }

.comment {
  padding: 10px;
  background: #F5F5F5;
  margin-bottom: 15px;
  border: 1px solid #ccc; }

.comment .info {
  color: #333;
  font-weight: 600;
  margin-bottom: 10px; }

.errorMessage {
  font-weight: bold;
  color: #f00; }

.archive {
  margin-bottom: 2rem; }

.archive .year > a {
  background-color: #006197;
  font-size: 1.125rem;
  color: #fff; }

.inline-form input, .inline-form select {
  width: auto;
  display: inline-block; }

.inline-form .button {
  margin-bottom: 0; }

.moduleHeaderFilters .freedomCategoryFilter {
  float: left;
  margin-left: 1rem; }

.freedomFilterLabel {
  font-weight: 600; }

/*! Pagination */
.pagination {
  clear: both;
  display: block; }

.pagination a, .pagination a:visited {
  text-decoration: none;
  color: #222222;
  font-weight: normal; }

.pagination .prevnextCurrent, .pagination .prevnextWindow, .pagination .prevnextWindowArrow, .pagination .prevnextLink {
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
  margin: 0;
  padding: 0.4em 0.7em;
  font-size: 0.875rem;
  margin-right: 2px;
  display: inline-block;
  border-radius: 0; }

.pagination .pageLinkPrev a {
  background-color: #F5F5F5;
  color: #222222; }

.pagination .pageLinkNext a {
  background-color: #F5F5F5;
  color: #222222; }

.pagination .prevnextWindow:hover, .pagination .prevnextLink:hover, .pagination .prevnextWindowArrow:hover {
  border: 1px solid #c51d24;
  color: #222222;
  background-color: #F5F5F5; }

.pagination .prevnextCurrent {
  background: #c51d24;
  color: #fff;
  border: 1px solid #c51d24;
  font-weight: bold; }

/* End Pagination */
/*! ModuleBegin::forms */
.formContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 36px; }

.formSubGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.formSubGroup .formGroup--medium {
  width: calc(50% - .75rem); }

.formFieldset, .formStaticText {
  width: 100%; }

.formGroup {
  display: block;
  margin-bottom: 0.5em;
  position: relative;
  z-index: 0;
  width: 100%;
  align-self: center; }

@media print, screen and (min-width: 64em) {
  .formGroup {
    margin-bottom: 1em; } }

fieldset.formInput-group {
  border: 2px solid #F5F5F5;
  border-radius: 0;
  padding: 0.75rem; }

fieldset.formInput-group legend {
  padding: 0 8px 0 5px; }

.formInput-columns {
  display: flex;
  flex-flow: row wrap; }

.formInput-columns label {
  flex: 0 1 auto;
  width: 100%;
  margin-bottom: 1rem; }

.formInput-columns label input {
  margin-bottom: 0; }

@media screen and (min-width: 40em) {
  .formInput-columns.columns-auto label {
    flex: 1 1 10%;
    width: auto; }
  .formInput-columns.columns-2 label {
    width: 50%; }
  .formInput-columns.columns-3 label {
    width: 33%; }
  .formInput-columns.columns-4 label {
    width: 25%; }
  .formInput-columns.columns-5 label {
    width: 20%; } }

.formGroup--aligned {
  display: flex;
  align-items: center; }

.formGroup--aligned .formLabel {
  white-space: nowrap;
  flex: 1 1 auto;
  padding-right: .75rem;
  margin-bottom: 1rem; }

.formGroup--checkbox.formGroup--aligned input {
  margin-bottom: 0; }

@media (min-width: 64em) {
  .formGroup--quarter {
    width: calc(25% - 0.75rem); }
  .formGroup--small {
    width: calc(33.333% - 0.75rem); }
  .formGroup--medium {
    width: calc(50% - 0.75rem); }
  .formGroup--large {
    width: calc(66.666% - 0.75rem); }
  .formGroup--full {
    width: 100%; } }

.formNavigation {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-around;
  width: 100%; }

.button + .formNavigation-next {
  margin-left: auto; }

.formInput[type=file] {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 2px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(34, 34, 34, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #222222;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  height: auto;
  padding: 0.25rem; }
  .formInput[type=file]:focus {
    outline: none;
    border: 2px solid #818181;
    background-color: #fff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

input[type=radio], input[type=checkbox] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #cacaca;
  outline: none; }

input[type=radio] {
  border-radius: 50%;
  vertical-align: top; }

input[type=radio]::after {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 2px solid #fff;
  border-radius: 50%; }

input[type=radio]:checked {
  background: #006197;
  border-color: #006197; }

input[type=radio]:checked::after {
  content: ' '; }

input[type=checkbox] {
  vertical-align: top; }

input[type=checkbox]::after {
  position: absolute;
  top: 50%;
  left: 5px;
  width: .6rem;
  height: 1rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-top: -0.75rem;
  color: #006197;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
  border-bottom: 3px solid;
  border-right: 3px solid;
  transform: rotate(45deg);
  box-sizing: border-box; }

input[type=checkbox]:checked {
  border-color: #006197; }

input[type=checkbox]:checked::after {
  content: ' '; }

.formGroup .formLabel {
  font-weight: 500;
  font-size: .9rem; }

.formLabel.formLabel--required::after {
  content: "*";
  color: red; }

/*! basic calendar popup css */
#ui-datepicker-div {
  background: #fff;
  border: 2px solid #333; }

.ui-datepicker {
  width: 20em;
  display: none; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0;
  background: #333;
  color: #fff; }

.ui-datepicker .ui-datepicker-header a {
  color: #fff; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 0;
  width: 70px;
  height: 1.8em; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
  text-align: left; }

.ui-datepicker .ui-datepicker-next {
  right: 2px;
  text-align: right; }

.ui-datepicker .ui-datepicker-prev span {
  cursor: pointer;
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-next span {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 45%; }

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 0.4em; }

.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0; }

.ui-datepicker td {
  border: 0;
  padding: 1px; }

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: center;
  text-decoration: none; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

/*! ModuleEnd::forms */
/*! ModuleBegin::membership */
.loginBox {
  margin: 0 auto;
  max-width: 450px;
  min-height: 522px;
  width: 100%;
  border-radius: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 1;
  background: #F5F5F5;
  position: relative;
  transition: top 450ms;
  top: 0; }

.primaryLogin.loginBox {
  max-width: 932px;
  top: -180px; }

.loginForm, .loginCta {
  padding: calc(1rem + 2vw);
  display: flex;
  flex-flow: column; }

.loginForm {
  background: #fff; }

.loginCta {
  justify-content: center;
  min-height: 250px; }

.membershipModule .companyInfo {
  margin: 1em 0; }

.membershipModule .address {
  margin: 1em 0; }

.membershipModule .contactInfo {
  margin-bottom: 1em; }

.membershipModule .thumbnail {
  float: left;
  margin-right: 20px; }

.membershipModule .detail-image {
  margin-right: 3rem; }

/*! ----- Membership Lister ----- */
.membershipModule .lister-image {
  width: 190px; }

.moduleSearchBox {
  background: #F5F5F5;
  border-top: 2px solid #cacaca;
  padding: 2rem;
  margin-bottom: 2rem; }

.moduleSearchBox .formContent, .moduleSearchBox .formGroup {
  margin-bottom: 0; }

/*! ---- Membership Detail ----- */
/*! ----- My Account ---- */
.membershipModule.myAccount::after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.myAccountLinks {
  margin-top: 35px;
  align-items: stretch; }

.myAccountLinks .myAccountBox {
  border: 0;
  border-bottom: 6px solid #006197;
  padding: 12px 16px;
  height: 100%; }

.myAccountLinks h3 {
  padding: 0 0 12px;
  margin: 0 0 12px;
  border-bottom: 1px solid #eee;
  font-size: 20px; }

.myAccountLinks .linkSection a {
  display: block;
  padding: 5px 0;
  color: inherit; }

.myAccountLinks .button {
  text-align: center;
  padding-top: 25px; }

.myAccountLinks .button a {
  margin: 0 0 10px; }

/*! ----- My Account - Dispatch ( System Forms ) ---- */
.freedomAdminTableHeader, .freedomAdminTableHeader table {
  margin: 0; }

.freedomAdminTableHeader tbody, .freedomAdminTableHeader tr {
  border: 0; }

#freedomAdminTableHeaderWrapper table, #freedomAdminTableHeaderWrapper tbody, #freedomAdminTableHeaderWrapper tr {
  margin: 0;
  padding: 0;
  border: 0; }

.myaccountForm tbody, .myaccountForm tr, .freedomAdminForm tbody, .freedomAdminForm tr {
  border: 0; }

.myaccountForm input[name$=city], .myaccountForm input[name$=state], .myaccountForm input[name$=zip],
.myaccountForm #bank_acct_name, .myaccountForm #bank_aba_code, .myaccountForm select[name$=month], .myaccountForm select[name$=year],
.myaccountForm input[name$=time], .myaccountForm select[name$=time_ampm], .freedomAdminForm input[name$=city], .freedomAdminForm input[name$=state], .freedomAdminForm input[name$=zip],
.freedomAdminForm #bank_acct_name, .freedomAdminForm #bank_aba_code, .freedomAdminForm select[name$=month], .freedomAdminForm select[name$=year],
.freedomAdminForm input[name$=time], .freedomAdminForm select[name$=time_ampm] {
  width: auto;
  display: inline-block; }

.myaccountForm select[name$=month], .myaccountForm select[name$=year], .freedomAdminForm select[name$=month], .freedomAdminForm select[name$=year] {
  width: 40%; }

.myaccountForm input[name$=city], .freedomAdminForm input[name$=city] {
  width: 98%; }

@media screen and (min-width: 40em) {
  .myaccountForm input[name$=city], .freedomAdminForm input[name$=city] {
    width: 50%; } }

#myaccount_form > div {
  text-align: left;
  width: 100%; }

.myaccountForm > table, .freedomAdminForm > table {
  width: 100%; }

.myaccountForm > table td, .freedomAdminForm > table td {
  vertical-align: top; }

.myaccountForm > table td:first-child, .freedomAdminForm > table td:first-child {
  padding-top: 14px;
  padding-right: 12px; }

.myaccountForm > table table, table.freedomTable {
  width: 100%;
  border: 1px solid #ccc;
  background: #f8f8f8;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse; }

.myaccountForm > table table td, table.freedomTable td, table.freedomTable th {
  vertical-align: middle;
  padding: 8px 12px; }

.myaccountForm > table table thead td, table.freedomTable thead th {
  background: #ccc;
  color: #000; }

.myaccountForm > table table thead td *, table.freedomTable thead th * {
  color: #000; }

.myaccountForm > table table thead td:first-child, table.freedomTable thead th:first-child {
  text-align: left; }

.editorLabel {
  display: inline-block;
  padding-bottom: 15px; }

.myaccountDetail table {
  color: #444; }

.myaccountDetail .formtextRequired, .myaccountDetail .formtext {
  padding: 5px 0; }

.myaccountDetail .formtextRequired label {
  font-weight: bold; }

.formButtons {
  padding-top: 15px; }

select.filterCombo {
  width: 200px; }

.imageBoxContainer {
  width: 100%;
  background: #eee;
  padding: 3px;
  line-height: 38px; }

.imageBoxContainer * {
  vertical-align: middle !important;
  text-align: center; }

.imageBoxContainer .imageBox {
  border: 4px solid #fff;
  background: #f8f8f8;
  display: inline-block;
  width: auto;
  margin: 8px;
  padding: 12px; }

.myaccountMessage {
  display: block;
  padding: 18px;
  border: 2px dashed #777;
  background: #ddd;
  color: #000;
  font-size: 1.6rem;
  line-height: 22px;
  text-align: center;
  margin-bottom: 18px; }

.myaccountMessage.defaultSuccess {
  background: white;
  border-color: #3adb76; }

.myaccountCta {
  padding: 2rem; }

.myaccount-infoContainer {
  background-color: #10314e;
  padding: 16px 0 1px 0;
  color: #fff;
  margin-bottom: 1rem; }

.myaccount-infoBar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center; }

/*! ModuleEnd::membership */
/*! ModuleBegin::events */
/*! ----- Events Glance View ----- */
#eventForm table tbody {
  border: 0; }

#eventCategoryFilter {
  max-width: 300px; }

.cal {
  position: relative;
  text-align: center; }

.calTop {
  margin-bottom: 0; }

.calTop a {
  transition: all 0.3s cubic-bezier(0.36, 0.53, 0.33, 1.01);
  opacity: .8;
  color: #ffffff;
  font-size: 1.25rem; }

.calTop a:hover {
  opacity: 1; }

.calTop td {
  background-color: #10314e;
  vertical-align: middle;
  text-align: center;
  color: #ffffff; }

.calMonth {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem; }

.calGrid {
  clear: both;
  margin-right: auto;
  margin-left: auto;
  background: transparent;
  width: 100%;
  border-collapse: collapse;
  text-align: center; }

.calGrid tbody, .calGrid tfoot, .calGrid thead {
  border: 0;
  background: 0 0; }

.calGrid td, .calGrid tr {
  border: none;
  background: 0 0; }

.calGrid td {
  position: relative;
  padding: 0;
  width: 14.2857%;
  height: 48px;
  vertical-align: middle;
  text-align: center; }

.calGrid tr.calWeek {
  border: none;
  background-color: #F5F5F5;
  color: #006197;
  text-align: center; }

.calGrid tr.calWeek td {
  border-top: 0;
  border-left: 0;
  height: 33px;
  font-size: 1rem;
  font-weight: 700; }

.calGrid td.today {
  background: #F5F5F5; }

.calGrid .dayDate {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 900; }

.show-for-smallCalendar, .show-for-largeCalendar {
  display: none; }

.largeCalendar .show-for-largeCalendar {
  display: table-row; }

.smallCalendar .show-for-smallCalendar {
  display: table-row; }

.cal.smallCalendar {
  max-width: 450px; }

.cal.smallCalendar .calGrid tr.calWeek td {
  border-top: 0;
  border-left: 0; }

.cal.smallCalendar .calGrid td:first-child {
  border-left: 0; }

.cal.smallCalendar .calGrid tr:first-child + tr td {
  border-top: 0; }

.cal.smallCalendar .calGrid td .glanceInner {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transition: all .3s;
  opacity: 0;
  margin-left: -78px;
  background: #10314e;
  padding: 8px 8px;
  width: 156px;
  text-transform: uppercase;
  line-height: 17px;
  color: #ffffff;
  font-size: 1.1rem; }
  .cal.smallCalendar .calGrid td .glanceInner a {
    display: inline-block;
    color: #ffffff;
    font-size: .8rem; }
    .cal.smallCalendar .calGrid td .glanceInner a:hover {
      text-decoration: underline; }
  .cal.smallCalendar .calGrid td .glanceInner::after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-8px);
    border-top: 8px solid #10314e;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: ''; }

.cal.smallCalendar .calGrid td:hover .glanceInner, .cal.smallCalendar .calGrid td.hovered .glanceInner {
  display: block;
  bottom: 38px;
  opacity: 1;
  z-index: 25; }

.cal.smallCalendar .calGrid td.eventOn, .cal.smallCalendar .calGrid td.eventOn a {
  cursor: pointer;
  font-weight: 700; }

.cal.smallCalendar .calGrid td span.corner-indicator {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0; }

.cal.smallCalendar .calGrid td .indicator {
  display: none; }

.cal.largeCalendar .calTop td {
  padding: 1rem; }

.cal.largeCalendar .calGrid td {
  border-right: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
  padding: .5rem .625rem 1rem;
  height: 150px;
  vertical-align: top;
  text-align: left; }

.cal.largeCalendar .calGrid td:first-child {
  border-left: 1px solid #F5F5F5; }

.cal.largeCalendar .calGrid a {
  color: #222222; }

.cal.largeCalendar .calGrid tr.calWeek td {
  border-right: 0;
  border-bottom: 0;
  height: auto;
  text-align: center; }

.cal.largeCalendar .calGrid td .glanceInner {
  display: block;
  padding-top: 7px;
  line-height: 1.2;
  font-size: 1rem; }

.cal.largeCalendar .calGrid .indicator {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5px;
  text-align: center;
  color: #818181;
  font-weight: bold;
  width: 100%; }

.cal.largeCalendar .glanceInner.moreEvents + .indicator {
  display: block; }

.glancePopup {
  display: none;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  z-index: 1;
  border: 1px solid #d8d8d8;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  background-color: #f6f6f6;
  padding: 1.5rem 1rem 1rem;
  width: 300px; }

.glancePopup.active {
  display: block; }

.glancePopup::after {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%) rotate(45deg);
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
  background-color: inherit;
  width: 15px;
  height: 15px;
  content: ''; }

.glancePopup.left {
  right: 100%;
  left: auto; }

.glancePopup.left::after {
  right: 0;
  left: auto;
  transform: translate(50%, -50%) rotate(45deg);
  box-shadow: 3px -1px 3px rgba(0, 0, 0, 0.1); }

.glancePopup .close-button {
  top: 0;
  right: 0.5rem; }

.glancePopup .title {
  font-weight: bold; }

.glancePopup a, .glanceInner a {
  display: block;
  margin-bottom: 10px; }

.glanceInner .time {
  display: none; }

.glanceInner.moreEvents a:nth-child(n+3) {
  display: none; }

/*! ---- Events Registration ----- */
.ticketTypeSummary thead {
  background: #006197;
  color: white; }

.ticketTypeSummary .ticketPriceTotal {
  text-align: right;
  font-size: 1.5rem;
  font-weight: bold; }

.ticketTypeSummary .ticketPriceTotal td {
  border-top: 1px solid #cccccc; }

.ticketQuantity {
  text-align: center; }

td.ticketTotal, .ticketPriceTotal td {
  text-align: right; }

.eventTicketTypeTitle {
  margin: 20px auto;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  white-space: nowrap; }

.eventTicketTypeTitle {
  counter-reset: attendee; }

.attendeeLabel::after {
  content: " " counter(attendee);
  counter-increment: attendee; }

#registrationSummary td {
  padding: 1px 10px; }

.ticketTypeTable thead {
  background: #006197;
  color: white; }

.ticketTypeTable {
  max-width: 650px; }

.ticketTypeSubmit {
  display: inline-block;
  transition: background-color .25s ease-out,color .25s ease-out;
  margin: 0 0 1rem 0;
  border: 1px solid transparent;
  border-radius: 2px;
  background: #c51d24;
  color: #fff;
  cursor: pointer;
  padding: .85em 1em;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  font-size: .9rem;
  font-weight: 600;
  -webkit-appearance: none; }

/*! Detail*/
.negativeMarginTop {
  position: relative;
  z-index: 1;
  margin-top: -240px; }

.eventIntro {
  position: relative;
  margin-bottom: 3rem;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
  background: white; }

.eventInfo {
  position: relative;
  padding: 2rem; }
  @media print, screen and (min-width: 64em) {
    .eventInfo {
      padding: 3rem; } }

.eventInfo-badge {
  position: absolute;
  top: 1rem;
  right: 0;
  border-radius: 0 0 0 0;
  background: #006197;
  padding: 5px;
  color: white;
  font-size: 1.125rem;
  font-weight: bold; }

.detailRow {
  display: flex;
  margin-bottom: 1rem;
  flex-flow: row wrap; }

.detailRow .label {
  width: 120px;
  flex: 0 0 auto; }

.eventImage {
  background-size: cover; }

/*! ModuleEnd::events */
/*! ModuleBegin::photo */
/*! Photo Lister */
.albumBox {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  height: 100%; }

.albumBox .albumImage {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 86%;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.albumBox .albumDesc {
  margin-bottom: 8px; }

/*! Thumbnails */
.thumbnailItem {
  display: block;
  height: 0;
  padding-bottom: 86%;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  border: 4px solid #fff;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.54); }

.thumbnailItem:hover {
  border-color: #c51d24;
  transition: border-color 0.1s ease; }

/*! Slideshow */
.photoModuleSlideshow img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%; }

/*! ModuleEnd::photo */
/* Product Add to Cart Widget */
.cartAddWidget {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  font-weight: bold; }

.cartAddWidget > div {
  margin-right: 1rem; }

.cartAddWidget .choice {
  display: block; }

.cartAddSubmit input[type="submit"] {
  padding: 10px 1.5rem; }

.cartAddSubmit input[type="submit"][disabled] {
  background: #999; }

.cartAddQty input[type="text"] {
  background-color: #fff; }

.cartAddSelect select {
  background-color: #fff; }

input.shoppingFormQuantity {
  width: auto;
  background: white; }

/*! Carts */
.shoppingCart {
  margin-bottom: 1rem; }
  .shoppingCart .name {
    font-size: 18px;
    font-weight: bold; }
  .shoppingCart .shoppingFormQuantity {
    display: inline-block;
    margin-bottom: 0; }
  .shoppingCart .cartRow {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }

.cartSubDetails {
  font-size: 0.8em; }

.cartDelete {
  color: #333; }
  .cartDelete:hover {
    color: #b52525; }

.shoppingAlert {
  color: red; }

.shoppingOutOfStock {
  color: #7e030e; }

.shoppingLowStock {
  color: #e79700; }

.shoppingCartTableHeader, .shoppingCartTableHeader td {
  background: #333;
  color: #fff;
  font-size: 1.125rem; }

.shoppingCartTable {
  border: 1px solid #dcdcdc; }

.shoppingCartTableFooter {
  font-size: 18px;
  font-weight: bold; }

.shoppingCartTable .product.even, .shoppingCartTable .product.even + .discount {
  background-color: #f2f2f2; }

.shoppingCartTable .quantity {
  text-align: center; }

.shoppingCartTable .total {
  text-align: right; }

.shoppingCartTable .description {
  text-align: left;
  font-size: 1.125rem; }

.shoppingCartButtons {
  display: flex;
  justify-content: space-between; }

.shoppingCartMini .total {
  font-weight: bold; }

.shoppingCartMini .shoppingCartMini-summary {
  padding: 1rem 0;
  border-top: 1px solid #cacaca; }

/*! Checkout page*/
#cart_charges_summary td {
  border-bottom: 1px solid #ddd;
  padding: 0.5em; }

.cartTotalPrice {
  font-size: 18px; }

/*! Checkout Form */
.checkout-form {
  counter-reset: section; }

.checkout-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: content-box;
  min-height: 2rem;
  padding: .5rem;
  background-color: #006197;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  transition: background 200ms; }

.checkout-form .step-bar::before {
  counter-increment: section;
  content: counter(section);
  margin-right: 1rem;
  background: white;
  color: #000;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px; }

.checkout-panel {
  margin-bottom: 2em;
  border: 1px solid #DDD;
  padding: 1em;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

#IDFormField_coupon_code_0, #IDFormField_giftcard_code_0 {
  width: calc(100% - 150px);
  display: inline-block; }

#applyCouponBtn, #applyGiftCardBtn {
  vertical-align: bottom; }

#refreshShippingBtn {
  display: none; }

.checkout-form, .checkout-summary {
  width: 100%;
  flex: 1 1 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem; }

#commerce_checkoutTable {
  display: flex;
  flex: 1 1 100%;
  flex-flow: row wrap; }

@media screen and (min-width: 50em) {
  #commerce_checkoutTable {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .checkout-form, .checkout-summary {
    flex: 0 1 auto; }
  .checkout-form {
    width: calc(60% - 1rem); }
  .checkout-summary {
    width: calc(40% - 1rem); } }

/*! ModuleBegin::directory-staff */
.staff-item .lister-image {
  width: 150px; }

.staff-item .lister-image .thumbnail {
  width: 150px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  margin-bottom: 0; }

.staff-item .lister-image .thumbnail.circle {
  border-radius: 50%;
  height: 150px; }

.staff-item .lister-image .thumbnail.square {
  height: 150px; }

.staff-item.lister-item {
  margin-bottom: 0.5rem;
  padding: 1rem; }

.staff-lister--boxed {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3); }
  .staff-lister--boxed .lister-image .thumbnail {
    width: 140px;
    height: 140px;
    overflow: hidden;
    margin-bottom: 0; }
  .staff-lister--boxed .lister-item {
    margin: 0; }

.staff-lister--alternating .lister-item:nth-child(4n+2),
.staff-lister--alternating .lister-item:nth-child(4n+3) {
  background: #F5F5F5; }

.photoBox--staff .photoBox-drawer.vertical {
  padding-top: 0;
  transform: translateY(calc(100% - 100px)); }

/*! ModuleEnd::directory-staff*/
/*! ----- Group Exercise  ------ */
/*! ModuleBegin::directory-schedule */
#scheduleRawItems {
  display: none; }

.pip {
  font-size: 7px;
  line-height: 14px;
  vertical-align: middle;
  margin-right: .5rem; }
  .pip.low {
    color: #bfc82e; }
  .pip.medium {
    color: #dd2b71; }
  .pip.high {
    color: #50bce1; }

#scheduleToggle {
  display: none; }

#scheduleWrapper {
  border: 2px solid #cacaca;
  border-radius: 4px;
  padding: 6px 10px; }

.scheduleLabels {
  margin: 4px 0 0 87px;
  line-height: 30px;
  display: flex; }
  .scheduleLabels > div {
    flex: 1 0 auto;
    margin: 0 .5%;
    text-align: center;
    font-weight: 700;
    width: 13%; }
  .scheduleLabels .scheduleDate {
    font-weight: 400; }

.rowTitle {
  position: relative;
  margin: 16px 0;
  height: 2px;
  background: #c3c3c3;
  page-break-after: avoid;
  break-after: avoid; }
  .rowTitle span {
    position: absolute;
    left: 0;
    padding: 0 10px;
    line-height: 36px;
    font-size: 17px;
    top: -18px;
    background: #fff; }

@media print {
  .rowTitle {
    border-top: 1px solid #c3c3c3; }
  .rowTitle span {
    top: unset; } }

.rowDays {
  margin: 4px 0 0 87px;
  min-height: 20px;
  height: auto;
  display: flex;
  page-break-inside: avoid;
  break-inside: avoid; }
  .rowDays > div {
    width: 13%;
    margin: 0 .5% 4px; }
  .rowDays .clear {
    display: none; }

.scheduleItem .itemPopup {
  display: none; }

.scheduleItem {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  text-align: left;
  padding: 3px;
  margin: 3px 0;
  cursor: pointer;
  font-size: 13px; }

.scheduleItem .itemName {
  font-weight: 700; }

.scheduleItem .itemTime {
  color: #006197;
  font-weight: 700; }

.itemPopup .popupTitle {
  color: #006197;
  font-size: 26px;
  text-align: center;
  margin: 0 0 10px;
  border-bottom: 1px solid #c3c3c3; }

.itemPopup .popupAlert {
  background: #ddd;
  padding: 1em;
  text-align: left;
  border-radius: 4px; }

.itemPopup b {
  display: inline-block;
  width: 105px; }

.itemPopup .popupLink {
  text-align: center;
  margin-top: 15px; }

.scheduleFilter select {
  display: inline-block;
  width: 300px; }

.scheduleFilter span {
  width: 150px;
  display: inline-block; }

@media screen and (max-width: 63em) {
  #scheduleToggle {
    display: block; }
  .rowDays > div, .scheduleLabels > div {
    width: 100%;
    display: none; }
  .rowDays > div.currentSchedule, .scheduleLabels > div.currentSchedule {
    display: block; } }

/*! ModuleEnd::directory-schedule */
