//this file should be included later so that it can override things
//generic 'theme' combos used across modules

////
/// @group global
////

/// Default theme palette map
/// @type Map
$theme-colors:(
        primary: $primary-color,
        secondary:$secondary-color,

        dark: $theme-dark,
        medium: $theme-medium,
        light: $theme-light,

        light-gray: $light-gray,
        medium-gray: $medium-gray,
        dark-gray: $dark-gray,
        darker-gray: #3e3e3e,
        black: #000
) !default;

@each $key, $color in $theme-colors {
  .theme-#{$key} {
    --theme-background: #{$color};
    background-color: $color !important;
    color: color-pick-contrast($color) !important;
    &.a:hover, &.a:focus {
      background-color: smart-scale($color, $button-background-hover-lightness);
    }
  }
}
