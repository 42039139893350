//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Header
//   3. Footer
//   4. Social Media
//   5. Mobile Nav
//   6. Content Framework
//   7. Sidemenu
//   8. Collapsibles / Expandies
//   9. Slideshow
//  10. Page Header
//  11. responsive_carousels
//  12. partners
//  13. Quicklinks
//  14. Info Cards
//  15. Testimonials
//  16. Photoboxes
//  17. Statistics
//  18. CTA - Spanning Stripe
//  19. multi_column
//  20. Split Content
//  21. General Modules
//  22. Pagination
//  23. Forms
//  24. Membership
//  25. Events
//  26. Photos
//  27. Commerce
//  28. Staff
//  29. Group Exercise

// 1. Globals
$eyebrow-color: $secondary-color;
$component-padding: (
        none: 0,
        small: 1rem,
        medium: 3rem,
        large: 5rem
);

$theme-colors:(
        primary: $primary-color,
        secondary:$secondary-color,


        dark: $theme-dark,
        medium: $theme-medium,
        light: $theme-light,

        light-gray: $light-gray,
        medium-gray: $medium-gray,
        dark-gray: $dark-gray,
        darker-gray: #3e3e3e,
        black: #000
);
// 2. Header - main menu
// ---------
//Header Style 1
$header-menu-background: $theme-dark;
$header-background: white;
$header-icon-color: $primary-color;
$header-main-navigation-color: #454545;
$header-first-level-color: color-pick-contrast($header-menu-background);

//Header Style 2
//$header-menu-background: white;
//$header-background: white;
//$header-icon-color: $primary-color;
$header-top-background:$theme-dark;
//$header-main-navigation-color: #454545;
//$header-first-level-color: color-pick-contrast($header-background);


// 3. Footer
$footer-main-background: $theme-dark;
$footer-main-link-color: $white;
$footer-main-link-weight: 500;
// 4. Social Media

$social-color: $primary-color;
$social-background-color: $white;
$social-border-radius: 50%;
$social-padding: .7rem 1rem;
$social-width:  1.6em;
$social-height:  1.6em;
$social-font-size: 1.5rem;

// 5. Mobile Menu

$mobilemenu-trigger-background: transparent;

$mobilemenu-trigger-color: $black;
$mobilemenu-header-trigger-color: white;
$mobilemenu-navigation-color: $black;

$mobilemenu-background: white;
$mobilemenu-background-secondary: $theme-dark;

$mobilemenu-trigger-breakpoint: 64em;
$mobilemenu-top-anchor-transform: null;

$mobilemenu-level-three: $dark-gray;
$mobilemenu-top: null;
$mobilemenu-inner-top: null;

$mobilemenu-toplevel-padding: 1rem;
$mobilemenu-toplevel-border: 1px solid smart-scale($mobilemenu-background );
$mobilemenu-font-weight: $global-weight-bold;
$mobilemenu-font-size: 1rem;
$mobilemenu-toplevel-font-weight: $global-weight-bold;

//   6. Content Framework
$sidebar-padding-top: 1rem;
$sidebar-boxed-background: $theme-medium;

//   7. Sidemenu
$sidemenu-color: $black;
$sidemenu-color-active: $dark-gray;
$sidemenu-font-family: null;

//   8. Collapsibles / Expandies
$collapsible-background: $light-gray;
$collapsible-icon-color: color-pick-contrast($light-gray,($primary-color, $secondary-color));
$collapsible-icon: plus;
$collapsible-font-family: inherit;
$collapsible-color: $header-color;
$collapsible-font-size: 1.125rem;

//   9. Slideshow
$slideshow-height-map: (
        small: 500px,
        large: 575px,
        xxlarge: 700px
);

$slideshow-background: $theme-dark;
$slideshow-slide-overlay-bg: linear-gradient(to bottom, $slideshow-background 0%, transparent 100%);
$slideshow-slide-overlay-height: 530px;

$slide-content-color: #FFF;
$slide-content-color-dark: $black;

$slideshow-slide-title-family: null;

$slideshow-alert-background: $secondary-color;
$slideshow-alert-color: $light-gray;
$slideshow-alert-icon-background: $primary-color;
$slideshow-alert-icon-color: color-pick-contrast($primary-color);

//  10. Page Header
$pageheader-background: $secondary-color;
$pageheader-color: $white;

$pageheader-background-alt: $theme-medium;
$pageheader-color-alt: $theme-dark;

$pageheader-image-overlay: $theme-dark;
$pageheader-image-overlay-type-full-cover: true;
$pageheader-image-overlay-opacity: .6;
$pageheader-overlay-direction: to right;
/// If `true`, images have overlay by default and an .noOverlay class is created. If `false`, a .hasOverlay class is created.
$pageheader-has-overlay: false;

//todo should page header use the generic theme classes
$pageheader-color-image: $white;

$pageheader-height: 250px;
$pageheader-image-height: 320px;

//  11. responsive_carousels
$flickity-button-background: null;
$flickity-button-color: null;
$carousel-margin-bottom: 2rem;

//  12. Partners slider
$partners-height: 175px;
$partners-width: 175px;
$partners-type-color: $primary-color;


//  13. Quicklinks
//you can add as many as you want to this. the keys can be names
$quicklink-palette: (
        1:$secondary-color,
        2:$primary-color,
        3:$theme-dark,
);
$quicklink-style: both;
$quicklinks-color: $black;

//  14. Info Cards
$card-image-background:$primary-color;
//  15. Testimonials

//  16. Photoboxes
$photobox-drawertab:$primary-color;

//  17. Statistics
$quickstat-background: $theme-medium;
$quickstat-background-blend:  null;
$quickstat-color: $primary-color;
$quickstat-hover-color: $secondary-color;
$quickstat-title-color: $secondary-color;
$quickstat-icon-color: $black;

$quickstat-overlay: null;

$quickstat-opacity: 0.5;
$quickstat-padding: null;

//  18. CTA - Spanning Stripe
//  19. multi_column
//  20. Split Content
$column-media-shadow: null;

//  21. General Modules
$lister-item-border-bottom: 2px solid rgba(0, 0, 0, 0.1);
$lister-cornerbadge-color: $primary-color;
$lister-dateicon-background: $black;
$lister-dateicon-radius: 50%;
$lister-dateicon-size: 60px;
$lister-dateicon-day-font-size: 1.5rem;
$category-color: $secondary-color;
$lister-item-striped-odd: $light-gray;

$archive-background: $secondary-color;

//  22. Pagination
//Also set the foundation pagination settings found in _settings.scss
$pagination-item-background: $light-gray;
$pagination-item-border: 1px solid $light-gray;
$pagination-item-border-hover: 1px solid $pagination-item-background-current;
$pagination-item-color-hover: $black;
$pagination-font-weight: normal;
$pagination-next-background: $pagination-item-background;
$pagination-next-color: $pagination-item-color;
$pagination-prev-background: $pagination-item-background;
$pagination-prev-color: $pagination-item-color;

//  23. Forms
$freedom-fieldset-border: 2px solid $light-gray;
$freedom-check-radio-border: 2px solid $light-gray;
$checked-input-color: $secondary-color;

//  24. Membership / Login / MyAccount
$login-box-top: -180px;

//  25. Events
$event-badge-background: $secondary-color;
$calendar-nav-background: $theme-dark;
$calendar-nav-color: #ffffff;

$calendar-popover-background: $theme-dark;
$calendar-popover-color: #ffffff;
$calendar-week-background: $light-gray;
$calendar-week-color: $secondary-color;
$calendar-small-width: 450px;
$calendar-small-width: 450px;

$events-table-header-background: $secondary-color;
$events-table-header-color: white;
//  26. Photos
//TODO

//  27. Commerce
$checkout-heading-background: $secondary-color;
$checkout-heading-color: $white;
$cart-header-background: #333;
//  28. Staff
$staff-alternating-background : $light-gray;
//todo other styles

//  29. Group Exercise
$schedule-item-time: $secondary-color;
$schedule-popup-title: $secondary-color;
$schedule-pip-map:(
        low: #bfc82e,
        medium: #dd2b71,
        high: #50bce1,
);